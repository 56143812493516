export const BANK_NAMES = [
  'オリックス銀行',
  'ソニー銀行',
  'イオン銀行',
  'じぶん銀行',
  '住信SBIネット銀行',
];
export const RISKY_BANK_NAMES = [
  'イオン住宅ローン',
  'SBJ銀行',
  '楽天銀行',
  '香川銀行',
  '滋賀銀行',
  '関西みらい銀行',
  'ダイヤモンドアセット',
  '東京スター銀行',
  'クレディセゾン',
  'スルガ銀行',
  '政策金融公庫',
  'きらぼし銀行',
  '三井住友トラスト',
  'その他',
];
