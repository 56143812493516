import React, { ReactNode, createContext, useState } from 'react';

// ----------------------------------------------------------------------

export const UserOrderByOptions = [
  'createdAt',
  'displayName',
  'firstName',
  'lastName',
  'email'
] as const;
export type UserOrderBy = typeof UserOrderByOptions[number];
export type UserPropertyOrderBy = 'createdAt';
export type Direction = 'asc' | 'desc';
export type SearchProps<T> = {
  limit: number;
  page: number;
  orderBy: T;
  order: Direction;
  filter?: string;
}

export type SearchContextProps = {
  users: SearchProps<UserOrderBy>;
  userProperties: SearchProps<UserPropertyOrderBy>;
  onChangeUserSearch: (search: Partial<SearchProps<UserOrderBy>>) => void;
  onChangeUserPropertySearch: (search: Partial<SearchProps<UserPropertyOrderBy>>) => void;
  resetState: (search: 'users') => void;
  resetStateByPath: (path: string) => void;
};

const initialState: SearchContextProps = {
  users: {
    limit: 10,
    page: 0,
    orderBy: 'createdAt',
    order: 'desc',
  },
  userProperties: {
    limit: 5,
    page: 0,
    orderBy: 'createdAt',
    order: 'asc',
  },
  onChangeUserSearch: () => { },
  onChangeUserPropertySearch: () => {},
  resetState: () => { },
  resetStateByPath: () => { },
};

const SearchContext = createContext(initialState);

type SearchProviderProps = {
  children: ReactNode;
};

function SearchProvider({ children }: SearchProviderProps) {
  const [searchOptions, setSearchOptions] = useState({
    users: initialState.users,
    userProperties: initialState.userProperties,
  });

  const onChangeUserSearch = (search: Partial<SearchProps<UserOrderBy>>) => {
    setSearchOptions({
      ...searchOptions,
      users: {
        ...searchOptions.users,
        ...search,
      },
    });
  }

  const onChangeUserPropertySearch = (search: Partial<SearchProps<UserPropertyOrderBy>>) => {
    setSearchOptions({
      ...searchOptions,
      userProperties: {
        ...searchOptions.userProperties,
        ...search,
      },
    });
  }

  const resetState = (search?: 'users' | 'userProperties') => {
    if (search) {
      setSearchOptions({
        ...searchOptions,
        [search]: initialState[search],
      });
    }
  };

  const resetStateByPath = (path: string) => {
    switch (path) {
      case '/admin/users':
        resetState('users');
        break;
      case '/admin/properties':
        resetState('userProperties');
        break;
      default:
        break;
    }
  }


  return (
    <SearchContext.Provider
      value={{
        ...searchOptions,
        onChangeUserSearch,
        onChangeUserPropertySearch,
        resetState,
        resetStateByPath,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}

export { SearchProvider, SearchContext };
