import { styled } from '@mui/material/styles';
import { ButtonHTMLAttributes, useCallback, useMemo, useState } from 'react';
import lineIcon from 'src/assets/img/line_44.png';
import useLocales from 'src/hooks/useLocales';
import axiosOpenApiInstance from 'src/utils/axiosOpenApi';

const LineButtonStyle = styled('button')(({ theme }) => ({
    backgroundColor: '#06C755',
    position: 'relative',
    border: 'none',
    borderRadius: 8,
    cursor: 'pointer',
    '& .LineButton-content': {
      display: 'flex',
      flexDirection: 'row',
    },
    '& .LineButton-text': {
      padding: '8px 16px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1,
      
      '& > div': {
        fontFamily: 'Helvetica Neue',
        fontWeight: 'bold',
        fontSize: 14,
        color: '#FFFFFF',
      }
    },
    '& .LineButton-line': {
      width: 1,
      height: 44,
      backgroundColor: '#000000',
      opacity: 0.08,
    },
    '& .LineButton-hover': {
      pointerEvents: 'none',
      backgroundColor: '#000000',
      opacity: 0,
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      transition: 'opacity linear 0.1s'
    },
    '&:hover .LineButton-hover': {
      opacity: 0.1,
    },
    '&:active:hover .LineButton-hover': {
      opacity: 0.3,
    },
    '&:disabled': {
      backgroundColor: '#FFFFFF',
      '& .LineButton-content img': {
        mixBlendMode: 'exclusion',
        opacity: 0.2,
      },

      '& .LineButton-hover': {
        pointerEvents: 'none',
        opacity: 0.6,
        border: '1px solid #E5E5E5',
        background: 'transparent',
        borderRadius: 8,
      },

      '& .LineButton-text > div': {
        color: '#1E1E1E',
        opacity: 0.2
      },
      '.LineButton-line': {
        backgroundColor: '#E5E5E5',
        opacity: 0.6
      },
    }
  }));

interface Props extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children' | 'onClick'> {
  lineType: 'login' | 'link' | 'unlink',
  afterComplete?: () => void,
}

export default function LineButton({ lineType, disabled, afterComplete, ...props }: Props) {
  const [isDisabled, setDisabled] = useState(false);
  const { translate } = useLocales();
  
  const title = useMemo(() => {
    switch (lineType) {
      case 'link':
        return translate('buttons.lineLink');
      case 'unlink':
        return translate('buttons.lineUnlink');
      case 'login':
      default:
        return translate('buttons.lineLogin');
    }
  }, [translate, lineType])

  const onClick = useCallback(async () => {
    setDisabled(true);
    if (lineType === 'link') {
      const { data } = await axiosOpenApiInstance.usersControllerLineAccountLink({
        withCredentials: true, 
      });
      setDisabled(false);
      window.location.href = data;
    }

    if (lineType === 'unlink') {
      await axiosOpenApiInstance.usersControllerLineAccountUnlink();
      setDisabled(false);
    }

    if (lineType === 'login') {
      const { data } = await axiosOpenApiInstance.usersControllerLineLogin({
        withCredentials: true,
      });
      setDisabled(false);
      window.location.href = data;
    }

    afterComplete?.();
  }, [afterComplete, lineType]);

  return (
    <LineButtonStyle onClick={onClick} disabled={isDisabled || disabled} {...props}>
      <div className="LineButton-content">
        <div>
          <img src={lineIcon} alt="line icon"/>
        </div>
        <div className="LineButton-line"/>
        <div className='LineButton-text'>
          <div>
            {title}
          </div>
        </div>
      </div>
      <div className='LineButton-hover' />
    </LineButtonStyle>
  )
}