// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";
const ROOTS_ADMIN = "/admin";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  line: path(ROOTS_AUTH, "/line"),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
  register: path(ROOTS_AUTH, "/register"),
  registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  verify: path(ROOTS_AUTH, "/verify"),
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  about: "/about-us",
  contact: "/contact-us",
  faqs: "/faqs",
  page404: "/404",
  page500: "/500",
  components: "/components",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  properties: {
    root: path(ROOTS_DASHBOARD, "/properties"),
    propertyById: path(ROOTS_DASHBOARD, "/properties/:propertyId"),
  },
  myProperties: {
    root: path(ROOTS_DASHBOARD, "/my-properties"),
    myPropertyById: path(ROOTS_DASHBOARD, "/my-properties/:propertyId"),
    newProperty: path(ROOTS_DASHBOARD, "/my-properties/new"),
  },
  user: {
    root: path(ROOTS_DASHBOARD, "/user"),
    profile: path(ROOTS_DASHBOARD, "/profile"),
    profileLine: path(ROOTS_DASHBOARD, "/profile/line"),
    cards: path(ROOTS_DASHBOARD, "/user/cards"),
    list: path(ROOTS_DASHBOARD, "/user/list"),
    newUser: path(ROOTS_DASHBOARD, "/user/new"),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, "/user/account"),
  },
  disclaimer: path(ROOTS_DASHBOARD, "/disclaimer"),
};

export const PATH_ADMIN = {
  users: {
    root: path(ROOTS_ADMIN, "/users"),
    properties: path(ROOTS_ADMIN, "/properties"),
  },
};

export const PATH_DOCS = "https://docs-minimals.vercel.app/introduction";
