import { fToNowInMonths } from './formatTime';

/**
 * 新築なら-20%下落、それ以外は-10%
 * @param builtAt
 * @returns
 */
export function getPropertyPriceDropRateFromBuiltAt(builtAt: string) {
  const isNewHouse = 0 >= fToNowInMonths(builtAt);
  return isNewHouse ? -20 : -10;
}
