import { ReactNode, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// pages
// components
import { PATH_DASHBOARD } from 'src/routes/paths';
import LoadingScreen from 'src/components/LoadingScreen';
import Login from 'src/pages/auth/Login';
import { UserDtoRolesEnum } from 'src/api-client';
import UserNameGuard from './UserNameGuard';

// ----------------------------------------------------------------------

type AdminGuardProps = {
  children: ReactNode;
};

export default function AdminGuard({ children }: AdminGuardProps) {
  const { isAuthenticated, isInitialized, user } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  if (!user?.roles.includes(UserDtoRolesEnum.Admin)) {
    return <Navigate to={PATH_DASHBOARD.root} />;
  }

  return <UserNameGuard>{children}</UserNameGuard>;
}
