import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import useLocales from "./useLocales";
import * as _ from "lodash";

interface IHeaderBreadcrumbs {
  heading: string;
  links: { name: string; href?: string }[];
}

const useHeaderBreadcrumbs = ({
  customHeading, // custom heading name for the last path of the header bread crumbs
}: {
  customHeading?: string;
}): IHeaderBreadcrumbs | undefined => {
  const { propertyId, userId } = useParams();
  const { pathname } = useLocation();
  const { translate } = useLocales();
  const [headerBreadcrumbs, setHeaderBreadcrumbs] =
    useState<IHeaderBreadcrumbs>();

  useEffect(() => {
    if (pathname) {
      if (propertyId === "") return;

      const pathList = pathname.split("/").filter((value) => value);
      const lastPath = pathList[pathList.length - 1];
      const links = pathList.map((path, index) => {
        const translationKey = pathList
          .slice(0, index + 1)
          .map(_.camelCase)
          .join(".");
        const href = "/" + pathList.slice(0, index + 1).join("/");

        if (lastPath === path) {
          if (propertyId === path) {
            return {
              name: propertyId,
            };
          }
          if (customHeading) {
            return {
              name: customHeading,
            };
          }
          return { name: translate(`${translationKey}.title`) };
        }

        if (propertyId === path) {
          return {
            name: propertyId,
            href,
          };
        }

        if (userId === path) {
          return {
            name: userId,
            href,
          };
        }

        if (userId) {
          const match = translationKey.match(new RegExp(`.${userId}`, "i"));
          if (match)
            return {
              name: translate(`${translationKey.replace(match[0], "")}.title`),
              href,
            };
        }

        return {
          name: translate(`${translationKey}.title`),
          href,
        };
      });
      setHeaderBreadcrumbs({
        heading: customHeading ? customHeading : links[links.length - 1].name,
        links,
      });
    }
  }, [pathname, translate, propertyId, userId, customHeading]);

  return headerBreadcrumbs;
};
export default useHeaderBreadcrumbs;
