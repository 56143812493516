import { createContext, ReactNode, useCallback, useEffect, useReducer, useState } from 'react';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
  signInWithCustomToken,
  sendPasswordResetEmail
} from 'firebase/auth';
import { getFirestore, doc, getDoc, DocumentData } from 'firebase/firestore';
// @types
import { ActionMap, AuthState, AuthUser, FirebaseContextType } from '../@types/auth';
//
import { FIREBASE_API } from '../config';
import axiosOpenApiInstance from 'src/utils/axiosOpenApi';
import { UserDtoRolesEnum } from 'src/api-client';


// ----------------------------------------------------------------------

// const ADMIN_EMAILS = [];

const firebaseApp = initializeApp(FIREBASE_API);

const AUTH = getAuth(firebaseApp);

const DB = getFirestore(firebaseApp);

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

enum Types {
  Initial = 'INITIALISE',
}

type FirebaseAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
};

type FirebaseActions = ActionMap<FirebaseAuthPayload>[keyof ActionMap<FirebaseAuthPayload>];

const reducer = (state: AuthState, action: FirebaseActions) => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, user } = action.payload;
    user?.getIdToken().then((idToken: string) => {
      localStorage.setItem('firebase-user-id-token', idToken);
    });
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }

  return state;
};

const AuthContext = createContext<FirebaseContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [profile, setProfile] = useState<DocumentData | undefined>();

  const getProfile = useCallback(async (uid: string) => {
    const userRef = doc(DB, 'users', uid);

    const docSnap = await getDoc(userRef);

    if (docSnap.exists()) {
      setProfile(docSnap.data());
    }
  }, []);

  useEffect(
    () =>
      onAuthStateChanged(AUTH, async (user) => {
        if (user) {
          await getProfile(user.uid);

          dispatch({
            type: Types.Initial,
            payload: { isAuthenticated: true, user },
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: { isAuthenticated: false, user: null },
          });
        }
      }),
    [dispatch, getProfile]
  );

  const login = async (email: string, password: string) =>
    signInWithEmailAndPassword(AUTH, email, password);
  
  const loginWithCustomToken = async (token: string) => signInWithCustomToken(AUTH, token)

  const register = async (email: string, password: string, firstName: string, lastName: string, displayName: string) => {
    try {
      await axiosOpenApiInstance.usersControllerRegister({
        registerUserDto: {
          email,
          firstName,
          lastName,
          displayName,
          password,
        }
      });
      await signInWithEmailAndPassword(AUTH, email, password);
    } catch (e) {
      console.error('User registration failed:', e);
    }
  }

  const resetPassword = async (email: string) => sendPasswordResetEmail(AUTH, email, {
    url: 'https://app.tokyo-1r.com/dashboard'
  })
  
  const logout = async () => {
    await signOut(AUTH);
    setProfile(undefined);
  }

  const reloadUser = async () => {
    const user = AUTH.currentUser;
    if (user) {
      await user.reload();
      await getProfile(user.uid);
    }
  }

  const changePassword = async (oldPassword: string, newPassword: string, confirmNewPassword: string) => {
    const user = AUTH.currentUser;
    if (!user) return;
    const credential = EmailAuthProvider.credential(user.email!, oldPassword);
    await reauthenticateWithCredential(user, credential);
    await updatePassword(user, newPassword);
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        user: {
          id: state?.user?.uid,
          email: state?.user?.email,
          emailVerified: state?.user?.emailVerified,
          firstName: profile?.firstName || '',
          lastName: profile?.lastName || '',
          photoURL: state?.user?.photoURL || profile?.photoURL,
          displayName: profile?.displayName,
          roles: profile?.roles || [UserDtoRolesEnum.User],
          phoneNumber: state?.user?.phoneNumber || profile?.phoneNumber || '',
          country: profile?.country || '',
          address: profile?.address || '',
          state: profile?.state || '',
          city: profile?.city || '',
          zipCode: profile?.zipCode || '',
          about: profile?.about || '',
          isPublic: profile?.isPublic || false,
          getIdToken: state?.user?.getIdToken(),
        },
        login,
        loginWithCustomToken,
        register,
        logout,
        reloadUser,
        changePassword,
        resetPassword
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
