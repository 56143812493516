import { useCallback, useEffect, useState } from 'react';
// @mui
import { Grid, Container, Stack, Button } from '@mui/material';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import { SkeletonPostItem } from '../../components/skeleton';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// sections
import { PropertyPostCard } from '../../sections/@dashboard/property';
import axiosOpenApiInstance from 'src/utils/axiosOpenApi';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import ImageCaption from 'src/components/ImageCaption';
import { GetPropertiesResponseDto } from 'src/api-client';
import useHeaderBreadcrumbs from 'src/hooks/useHeaderBreadcrumbs';
import useLocales from 'src/hooks/useLocales';
import { FormProvider, RHFSelect } from 'src/components/hook-form';
import { PREFECTURES } from 'src/utils/prefectures';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

export default function PropertiesPost() {
  const { themeStretch } = useSettings();
  const { translate } = useLocales();
  const headerBreadcrumbs = useHeaderBreadcrumbs({});

  const isMountedRef = useIsMountedRef();

  // response
  const [propertiesResponse, setPropertiesResponse] = useState<GetPropertiesResponseDto | null>(
    null
  );
  const [isPostsLoading, setIsPostsLoading] = useState(false);
  const [, setError] = useState(null);

  // router
  const { pathname, search } = useLocation();
  const query = new URLSearchParams(search);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(20);
  const [filterName, setFilterName] = useState(query.get('filterBy') || '');

  const getProperties = useCallback(
    async (offset: number, limit: number, _filterName?: string) => {
      setIsPostsLoading(true);
      try {
        const response = await axiosOpenApiInstance.propertiesControllerFindAll({
          page: offset,
          limit,
          orderBy: 'createdAt',
          filterBy: _filterName
        });

        if (isMountedRef.current) {
          setPropertiesResponse({
            data: response.data.data,
            totalItems: response.data.totalItems,
          });
        }
      } catch (error) {
        console.error(error);
        setError(error.message);
      }
      setIsPostsLoading(false);
    },
    [isMountedRef]
  );

  useEffect(() => {
    getProperties(page, rowsPerPage, filterName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoadMoreProperties = async () => {
    const nextPage = page + 1;
    const response = await axiosOpenApiInstance.propertiesControllerFindAll({
      page: nextPage,
      limit: rowsPerPage,
      orderBy: 'createdAt',
      filterBy: filterName
    });
    setPropertiesResponse({
      data: propertiesResponse?.data.concat(response.data.data) || [],
      totalItems: response.data.totalItems,
    });
    setPage(nextPage);
  };

  const methods = useForm();
  const { handleSubmit } = methods;
  const onSubmit = (e: unknown) => {};

  const handleOnChange = (event: any) => {
    const filterName = event.target.value as string;
    setPage(0);
    setFilterName(filterName);
    navigate({
      pathname,
      search: `?filterBy=${filterName}`,
    });
    getProperties(0, rowsPerPage, filterName);
  };

  return (
    <Page title={headerBreadcrumbs?.heading || ''}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        {headerBreadcrumbs && <HeaderBreadcrumbs {...headerBreadcrumbs} />}

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <RHFSelect
            name="prefecture"
            label={translate('texts.selectPrefecture')}
            value={filterName}
            onChange={handleOnChange}
          >
            {PREFECTURES.map((label) => (
              <option key={label} value={label}>
                {label}
              </option>
            ))}
          </RHFSelect>
        </FormProvider>

        {/* <PropertyListToolbar
          // numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
          // onDeleteProducts={() => handleDeleteProducts(selected)}
          onBlur={handleOnBlur}
        /> */}

        <Stack mb={1} direction="row" alignItems="center" justifyContent="end">
          <ImageCaption />
        </Stack>

        <Grid container spacing={3}>
          {(isPostsLoading && !propertiesResponse?.totalItems
            ? [...Array(12)]
            : propertiesResponse?.data || []
          ).map((property, index) =>
            property ? (
              <Grid key={property.id} item xs={12} sm={6}>
                <PropertyPostCard property={property} index={index} />
              </Grid>
            ) : (
              <SkeletonPostItem key={index} />
            )
          )}
        </Grid>

        {(propertiesResponse?.data?.length || 0) < (propertiesResponse?.totalItems || 0) && (
          <Stack mt={2} direction="row" alignItems="center" justifyContent="center">
            <Button variant="text" onClick={handleLoadMoreProperties}>
              {translate('buttons.loadMore')}...
            </Button>
          </Stack>
        )}
      </Container>
    </Page>
  );
}
