import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// components
import { PATH_DASHBOARD } from 'src/routes/paths';

// ----------------------------------------------------------------------

type UserNameGuardProps = {
  children: ReactNode;
};

export default function UserNameGuard({ children }: UserNameGuardProps) {
  const { user } = useAuth();
  const { pathname } = useLocation();

  if (pathname !== PATH_DASHBOARD.user.profile && (!user?.firstName || !user?.lastName || !user?.displayName)) {
    return <Navigate to={PATH_DASHBOARD.user.profile} />;
  }

  return <>{children}</>;
}
