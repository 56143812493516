// @mui
import { Stack, Button, Typography } from '@mui/material';
import Iconify from 'src/components/Iconify';
import useLocales from 'src/hooks/useLocales';
// assets
import { DocIllustration } from '../../../assets';

// ----------------------------------------------------------------------

export default function NavbarDocs() {
  const { translate } = useLocales();

  return (
    <Stack
      spacing={3}
      sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center', display: 'block' }}
    >
      <DocIllustration sx={{ width: 1 }} />

      <div>
        <Typography gutterBottom variant="subtitle1">
          {translate('texts.wantToSellProperty')}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {translate('texts.wantToSellPropertyDescription')}
        </Typography>
      </div>

      <Button
        variant="contained"
        href="https://tokyo-1r.com/baikyakukun/"
        target="_blank"
        endIcon={<Iconify icon="eva:external-link-outline" />}
      >
        {translate('buttons.open')}
      </Button>
    </Stack>
  );
}
