import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// pages
// components
import { PATH_AUTH, PATH_DASHBOARD } from 'src/routes/paths';

// ----------------------------------------------------------------------

type VerifiedGuardProps = {
  children: ReactNode;
};

export default function VerifiedGuard({ children }: VerifiedGuardProps) {
  const { user } = useAuth();

  if (!user!.emailVerified) {
    return <Navigate to={PATH_AUTH.verify} />;
  }

  return <>{children}</>;
}

export function NotVerifiedGuard({ children }: VerifiedGuardProps) {
  const { user } = useAuth();

  if (user!.emailVerified) {
    return <Navigate to={PATH_DASHBOARD.root} />;
  }

  return <>{children}</>;
}
