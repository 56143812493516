import {
  GetSimulateResultResponseDto,
  PropertyAdvancedSettings,
  PropertyBasicSettings,
  PropertyVolatilitySettings,
} from 'src/api-client';

type basicSettingsKey = keyof PropertyBasicSettings;
type advancedSettingsKey = keyof PropertyAdvancedSettings;
type volatilitySettingsKey = keyof PropertyVolatilitySettings;
type simulateResultKey = keyof GetSimulateResultResponseDto;

export interface IPropertySettingInfo {
  description: string;
  referenceUrl: string;
  referenceTitle?: string;
}

export function getPropertyBasicSettingsInfo(
  key: basicSettingsKey
): IPropertySettingInfo | undefined {
  return PROPERTY_BASIC_SETTING_INFO[key];
}

export function getPropertyAdvancedSettingsInfo(
  key: advancedSettingsKey
): IPropertySettingInfo | undefined {
  return PROPERTY_ADVANCED_SETTING_INFO[key];
}

export function getPropertyVolatilitySettingsInfo(
  key: volatilitySettingsKey
): IPropertySettingInfo | undefined {
  return PROPERTY_VOLATILITY_SETTING_INFO[key];
}

export function getSimulateResultInfo(key: simulateResultKey): IPropertySettingInfo | undefined {
  return SIMULATION_RESULT_INFO[key];
}

const PROPERTY_BASIC_SETTING_INFO: {
  [key in keyof Partial<PropertyBasicSettings>]: IPropertySettingInfo;
} = {
  rentCollectionServiceFee: {
    description:
      'ワンルームマンション投資とするにあたって、毎月かかる経費です。ワンルームマンション投資をするにあたって、ほとんどのオーナーさんは賃貸管理を業者に委託します。その委託手数料が「集金代行費（賃貸管理費）」です。',
    referenceUrl: 'https://tokyo-1r.com/syuukindaikou',
  },
  isRentRevenueGuaranteed: {
    description:
      '家賃保証とは、お部屋を管理会社が借り上げて、それを入居者へと転貸する仕組みを言います。<br><br>入居者はあくまで管理会社なので、毎月一定の保証賃料が必ず振り込まれる仕組みです。<br><br>しかし、家賃保証はトラブルも多く非常にデメリットが大きいのでお勧めはできません。',
    referenceUrl: 'https://tokyo-1r.com/yachinhosyou-2',
  },
  initialPayment: {
    description:
      '物件価格とローン金額の差額です。例えば２５００万円の物件を購入するのに、２４００万円のローンを組むのであれば、その差額１００万円が頭金となります。ワンルームマンション投資では、頭金を多く入れれば毎月のローン返済額が少なくなりますので、毎月の収支は良くなります。',
    referenceUrl: 'https://tokyo-1r.com/atamakin',
  },
  managementCost: {
    description:
      'ワンルームマンション投資をするのにあたって、毎月かかる経費です。建物管理会社から修繕積立金と一緒に毎月引き落としされます。管理費とは、マンションの共有部（エントランス、廊下、エレベーター、管理人の給与など、自分のお部屋の中以外の部分）の維持管理をする為に使われるお金です。建物管理会社は購入時に既に決まっているので、自分一人だけで変更などはできません。お部屋の中の「賃貸管理」とは別なので、混同しないように注意してください。',
    referenceUrl: 'https://tokyo-1r.com/kanrihi',
  },
  repairReserve: {
    description:
      'ワンルームマンション投資をするにあたって、毎月かかる経費です。建物管理会社から管理費と一緒に毎月引き落としされます。修繕積立金とは、マンション共有部（エントランス、廊下、エレベーター、外壁など）を修繕するために積み立てるお金です。建物管理会社は購入時に既に決まっているので、自分一人だけで変更などはできません。',
    referenceUrl: 'https://tokyo-1r.com/syuuzentumitate',
  },
  annualInterestRate: {
    description:
      '金融機関からお金を借りる際に発生する金利のことです。<br/><br/>投資用のワンルームマンションを購入する際には「物件売主業者の提携金融機関」を使用することがほとんどです<br/><br/>代表的な金融機関は、<br/><br/>「オリックス」<br/><br/>「ダイヤモンドアセット」<br/><br/>「ソニー」<br/><br/>「ジャックス」<br/><br/>「イオン」<br/><br/>「東京スター」<br/><br/>などが挙げられます。<br/><br/>当然各金融機関で金利も異なります。<br/><br/>2%以上の金利で提案を受けている場合は注意してください。<br/><br/>また、その逆に1%前後の金利で提案を受けている場合も「なんちゃってスキーム」の可能性がありますので、要注意です。',
    referenceUrl: 'https://tokyo-1r.com/riritsu',
  },
  borrowingPeriodInYears: {
    description:
      'ローンを組む際の借入年数のことです。<br/><br/>通常は35年のフルローンを使います。<br/><br/>最近では金融機関によっては45年ローンも使うことが出来るようになりました。<br/><br/>借入期間が長くなれば、毎月の金融機関への支払も少なくなります。<br/><br/>そうなると、毎月の物件の収支も一見すると良く見えます。<br/><br/>45年という超長期間のローンで「収支が良い」のは当たり前のことなのです。<br/><br/>ですから、基本的に収支計算する際は35年ローンで計算し直してください。<br/><br/>物件の立地にもよりますが、35年ローンで毎月赤字になってしまうような物件はおすすめできません。<br/><br/>また、ローンを組む際はできるだけ最長で組んでください。',
    referenceUrl: 'https://tokyo-1r.com/kariirekikann',
  },
};

const PROPERTY_ADVANCED_SETTING_INFO: {
  [key in keyof Partial<PropertyAdvancedSettings>]: IPropertySettingInfo;
} = {
  propertyAcquisitionTax: {
    description:
      '読んで字のごとく、不動産を取得した際に１度だけかかる税金です。これは購入以外にも贈与（タダで貰った場合）でもかかってきますので注意してください。ワンルームマンションを購入後にその不動産の所在する都道府県から請求が来ます。請求は購入後３カ月から１年半後くらいにやってきます。忘れたころに請求が来るので要注意です。ワンルームマンションの不動産取得税はおおよそ１０～２０万程度です。',
    referenceUrl: 'https://tokyo-1r.com/syutokuzei',
  },
  otherPayments: {
    description:
      'ワンルームマンション購入に際して、物件価格以外にもお金がかかってきます。例えば２５００万円の物件を２５００万円のフルローンで物件を購入したとしましょう。頭金は０円なので、自己資金０円で始められる！？と思うかもしれませんが、そうではありません。ワンルームマンション（不動産）を購入するのに物件価格とは別に「諸費用」がかかってきます。',
    referenceUrl: 'https://tokyo-1r.com/syohiyou',
  },
  propertyTax: {
    description:
      'ワンルームマンション購入後から毎年かかる税金です。\nワンルームマンション購入後にその不動産の所在する市区町村から請求が来ます。\n請求は毎年４月～６月頃に通知が来ます。一括での支払もできますし、４回に分けての分納も可能です。ワンルームマンションの固定資産税はおおよそ４～８万程度です。不動産取得税とは違って、固定資産税は毎年かかる経費なのでしっかりとシミュレーションしなければなりません。',
    referenceUrl: 'https://tokyo-1r.com/koteisisanzei',
  },
  fireInsurance: {
    description:
      '火災保険にかかる年間費用です。投資用のワンルームマンションを購入する際に、オーナーは火災保険に加入しなければなりません。１０年更新で、火災保険料は大体15,000円程度です。更新の際には再度火災保険料が必要となります。',
    referenceUrl: 'https://tokyo-1r.com/kasaihoken',
  },
  bathroomDryer: {
    description:
      '浴室内乾燥機にかかる年間費用です。浴室内が乾燥機になる装置です。外に洗濯物が干せない時（特に女性の方）や梅雨の時期などは重宝します。取り付け、既存の浴室乾燥機撤去、工賃など含めて100,000円前後です。',
    referenceUrl: 'https://tokyo-1r.com/yokusitu',
  },
  airConditioner: {
    description: '取り付け、既存のエアコン撤去、工賃など含めて80,000円前後です。',
    referenceUrl: 'https://tokyo-1r.com/eakon',
  },
  boiler: {
    description:
      '給湯器工事にかかる年間費用です。取り付け、既存の給湯器撤去、工賃など含めて150,000～200,000円程度です。給湯器の寿命は3000時間と言われています。よって、一日１時間使用するとして、3000日。「3000日÷365日＝8.21年」。自宅にいない日や給湯器自体を使わない日もあるので、そこも考えれば約10年程度を交換周期と見ておけば良いでしょう。',
    referenceUrl: 'https://tokyo-1r.com/kyuutouki',
  },
  wallpaperReplacement: {
    description:
      'クロス張り替えにかかる年間費用です。クロス張り替えの平均単価は１㎡当たり900円～1000円程度です。ワンルームの１部屋ですと、20㎡（床面積）×３（天井や側面など）＝60㎡程度が居室部分全面の張り替え費用です。よって、クロス全面張替えだと、「900円×60㎡＝54,000円」程度のクロス張り替え費用がかかります。全面張替えは入居者の使用の仕方にもよりますが、6年～10年程度の期間で張り替えることが多いです。',
    referenceUrl: 'https://tokyo-1r.com/kabe',
  },
  floorReplacement: {
    description:
      'フローリングの張り替えにかかる年間費用です。フローリングの張り替えの平均単価は1㎡当たり4000円～7000円程度です。既存のフローリングの上から、さらにフローリングを「重ね張り」する手法であれば、もう少し安価です。ワンルームの１部屋ですと、「20㎡（床面積）×5000円＝100,000円」程度のフローリング張り替え費用がかかります。張り替えに関して20年～30年程度の期間で張り替えることが多いです。',
    referenceUrl: 'https://tokyo-1r.com/yuka',
  },
};

const PROPERTY_VOLATILITY_SETTING_INFO: {
  [key in keyof Partial<PropertyVolatilitySettings>]: IPropertySettingInfo;
} = {
  propertyPriceDropRate: {
    description:
      '初年度売却時の物件価格下落率です。投資用のワンルームマンションを購入し、直ぐに（初年度に）売却しようとした場合の物件価格の下落率であり、「物件予想売却価格」の指標となります。</br><br/>投資用のワンルームマンションは購入直後が物件価格の下落が最も大きいと考えてください。</br><br/>新築のワンルームマンションなどは特にその傾向が顕著に現れます。</br><br/>一度購入し、次に売却する際には築年数問わず中古扱いとなります。</br><br/>つまり新築のプレミアは最初だけということです。</br><br/>新築の場合は購入後直ぐに売却すると、新築価格の約２０%程度の価格下落を覚悟しなければなりません。</br><br/>つまり定価３０００万の新築ワンルームであれば、購入後すぐに売却すると、売却価格は２４００万程度になるということです。',
    referenceUrl: 'https://tokyo-1r.com/syonendobukkennkakaku',
  },
  rentChangeRate: {
    description:
      '家賃の上昇・下落を設定できます。<br/><br/>デフォルト値はｰ0.5%となっておりますが、例えば90000円の家賃設定の場合。<br/><br/>初年度は90000円<br/><br/>２年目は89550円<br/><br/>３年目は89102円<br/><br/>と、0.5%ずつ下落していくシミュレーションとなります。',
    referenceUrl: 'https://tokyo-1r.com/yachinhendou',
  },
  vacancyRate: {
    description:
      'ワンルームマンション投資において、空室になってしまうと家賃収入は入ってきません。<br/><br/>毎月の家賃収入が90000円とすると、年間の家賃収入は満額で1080000円となります。<br/><br/>例えば、空室率を5%と設定すると、<br/><br/>1,080,000円×5%（空室率）＝54,000円<br/><br/>これが年間空室分の家賃ということなります。<br/><br/>よって空室率5%を考慮した年間家賃収入は<br/><br/>1080000円ｰ54000円＝1026000円となります。<br/><br/>月の家賃にならすと<br/><br/>1,206,000円÷12＝85,500円となり、空室率5%を考慮した月の家賃収入となります。',
    referenceUrl: 'https://tokyo-1r.com/kuushituritu',
  },
  sellingPriceChangeRate: {
    description:
      '物件予測売却価格の上昇・下落を設定できます。<br/><br/>デフォルト値はｰ0.5%となっておりますが、例えば初年度の物件予測売却価格が2400万の場合。<br/><br/>初年度2400万円<br/><br/>２年目2388万円<br/><br/>３年目2376万円<br/><br/>と、0.5%ずつ下落していくシミュレーションとなります。',
    referenceUrl: 'https://tokyo-1r.com/baikyakuhendou',
  },
};

const SIMULATION_RESULT_INFO: {
  [key in keyof Partial<GetSimulateResultResponseDto>]: IPropertySettingInfo;
} = {
  annualCashNet: {
    description:
      '年収支（月収支×１２）に固定資産税や設備のメンテナンス費用などを合計したものです。<br/><br/>簡単に言えばその年にかかった経費全てに年収支を合計したものです。<br/><br/>なので、初年度に関しては「頭金」や「諸費用」など非常に出費が多いため、大きくマイナスとなるでしょう。<br/><br/>また、１０年、２０年が経過した際にクロス張り替えやエアコンの取り換えなど、メンテナンス費用がかかる年には、それらの経費もその年の「年単位総収支」に反映されます。',
    referenceUrl: 'https://tokyo-1r.com/nentannisoussyuusi',
  },
  accumulatedAnnualAllCashNet: {
    description: '年収支を累計したものです。',
    referenceUrl: 'https://tokyo-1r.com/ruikei',
  },
  remainingDebtAtEndOfYear: {
    description:
      '投資マンションローンの残債です。<br/><br/>このシミュレーションではマンション投資のスタート月などの細かな設定まではできません。<br/><br/>実際の詳細な年末ローン残高とは若干異なりますので、あくまで目安程度でお考え下さい。',
    referenceUrl: 'https://tokyo-1r.com/zansai',
  },
  estimatedSellingPrice: {
    description:
      '物件の予想売却価格です。<br/><br/>このシミュレーションはあくまで初年度の物件価格が予想売却価格の指標となっています。<br/><br/>よって、相場より高値で購入した場合などであっても、その高値で購入した価格自体が予想売却価格の最初の指標となってしまいます。<br/><br/>周辺の価格相場をしっかり確認してください。<br/><br/>特に新築ワンルームマンションなどは、購入した瞬間に予想売却価格が定価の80%程度まで下落すると考えてください。<br/><br/>例えば、３０００万の新築ワンルームであれば、初年度の予想売却価格はシミュレーション上は３０００万となりますが、実際にはそこから２０%程度の価格下落を考慮しなければなりません。<br/><br/>よって新築ワンルームの当初の予想売却価格は２４００万程度になってしまうと認識しておいてください。',
    referenceUrl: 'https://tokyo-1r.com/yosoubaikyaku',
  },
  estimatedRevenue: {
    description:
      '物件をその時点で売却した際に、得られるであろう予測売却収益額です。計算式は、<br/><br/>「予測売却収益＝予測売却価格ー残債＋累計収支」<br/><br/>となります。<br/><br/>累計収支は、その売却時までに出ていったり入ってきた費用（頭金、諸費用、毎月の収支、固定資産税・・・etc）などもろもろのお金のトータル累計です。<br/><br/>累計収支がマイナスだったとしても、物件価格を維持できれば売却益を残すことが可能です。<br/><br/>基本的に区分のワンルームマンションは投資利回りが低いので、毎月の収支で大きな＋収益を生むことは物理的に難しいです。<br/><br/>なので、累計収支で考えるとほとんどの物件はマイナスとなるでしょう（特に新築ワンルームなど）。<br/><br/>だからこそ、ワンルームマンション投資では「いかに家賃と価格が下がらない立地で物件を所有するか？」が最も大切なことなのであります。',
    referenceUrl: 'https://tokyo-1r.com/yosoubaikyakukakaku',
  },
};

export const SELLING_ESTIMATION_INFO = {
  purchasePrice: {
    description:
      'この物件を<a href="https://tokyo-1r.com/entry/differences-seller-properties-brokerage-properties/" target="_blank">売主業者</a>から購入した場合の参考価格になります。<a href="https://tokyo-1r.com/entry/costs-other-payment-0yen/" target="_blank">諸経費</a>は別途必要です。<br>検討中の物件がこの参考値よりも高い場合、または非常に低い場合には要注意です。<br>必ず何かしらの理由があると思ってください。<br>割安な価格で都心のワンルームを購入したいと思うでしょうが、<a href="https://tokyo-1r.com/entry/difficult-to-buy-cheap/" target="_blank">相場より安く物件を購入することは一般の方には難しいです。</a><br>しっかりと<a href="https://tokyo-1r.com/entry/lp/seminar/" target="_blank">東京１Rのセミナー</a>などで学んだ上で慎重に購入活動を行いましょう。',
  },
  sellingPrice: {
    description:
      'この物件を売却する際の参考価格です。<br><a href="https://tokyo-1r.com/entry/how-to-sell-from-1to10/" target="_blank">ワンルームの売却</a>は一般の不動産と商流が大きく異なります。売り先にもよりますが、仲介での売却であれば別途仲介手数料や諸経費が必要となります。<br>また、物件の総戸数、立地、㎡、構造、間取り、物件のシリーズ、管理会社、建物管理会社の管理状況、入居者、賃貸の保証会社はどこか？など状況によって大きく参考値を下回る可能性があります。<br>しっかりと<a href="https://tokyo-1r.com/entry/lp/seminar/" target="_blank">東京１Rのセミナー</a>などで学んだ上で慎重に売却活動を行いましょう。',
  },
};
