// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, TextFieldProps } from '@mui/material';

// ----------------------------------------------------------------------

interface IProps {
  name: string;
  handleOnChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void | Promise<void>;
}

export default function RHFTextField({ name, handleOnChange, ...other }: IProps & TextFieldProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          InputLabelProps={{ shrink: true }}
          error={!!error}
          helperText={error?.message}
          {...other}
          onChange={(e) => {
            field.onChange(e);
            handleOnChange?.(e);
          }}
        />
      )}
    />
  );
}
