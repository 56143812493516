// useRailwayDataHooks.ts
import { useState, useEffect } from 'react';
import axiosOpenApiInstance from './axiosOpenApi';

// 1. 鉄道会社を取得するカスタムフック
export const useRailwayCompanies = () => {
  const [railwayCompanies, setRailwayCompanies] = useState<
    { name: string; code: string; lines: string[] }[]
  >([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRailwayCompanies = async () => {
      try {
        const response = await axiosOpenApiInstance.railwayCompaniesControllerFindAll();
        setRailwayCompanies(
          response.data.data.map(({ id: code, name, lines }) => ({
            name,
            code: code || '',
            lines: lines || [],
          }))
        );
      } catch (error) {
        console.error('Error fetching railway companies:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRailwayCompanies();
  }, []);

  return { railwayCompanies, loading };
};

// 2. 鉄道会社の路線を取得するカスタムフック
export const useRailwayLines = () => {
  const [railwayLines, setRailwayLines] = useState<
    { name: string; code: string; stations: string[] }[]
  >([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRailwayLines = async () => {
      try {
        const response = await axiosOpenApiInstance.railwayLinesControllerFindAll();
        setRailwayLines(
          response.data.data.map(({ id: code, name, stations }) => ({
            name,
            code: code || '',
            stations: stations || [],
          }))
        );
      } catch (error) {
        console.error('Error fetching railway lines:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRailwayLines();
  }, []);

  return { railwayLines, loading };
};

// 3. 路線の駅を取得するカスタムフック
export const useRailwayStations = (lineCode: string | null) => {
  const [railwayStations, setRailwayStations] = useState<{ name: string; code: string }[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!lineCode) {
      return;
    }

    const fetchRailwayStations = async () => {
      try {
        const response = await axiosOpenApiInstance.railwayStationsControllerFindAll({
          lineCode,
        });
        setRailwayStations(
          response.data.data.map(({ id: code, name }) => ({
            name,
            code: code || '',
          }))
        );
      } catch (error) {
        console.error('Error fetching railway stations:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRailwayStations();
  }, [lineCode]);

  return { railwayStations, loading };
};
