import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const theme = useTheme();
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  // const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 200, height: 40, ...sx }}>
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512 512">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
          <path
            fill="url(#BG1)"
            d="M183.168 285.573l-2.918 5.298-2.973 5.363-2.846 5.095-2.274 4.043-2.186 3.857-2.506 4.383-1.6 2.774-2.294 3.939-1.099 1.869-1.416 2.388-1.025 1.713-1.317 2.18-.95 1.558-1.514 2.447-.866 1.38-.833 1.312-.802 1.246-.77 1.18-.739 1.111-.935 1.38-.664.956-.425.6-.41.572-.59.8-.376.497-.537.69-.171.214c-10.76 13.37-22.496 23.493-36.93 29.334-30.346 14.262-68.07 14.929-97.202-2.704l72.347-124.682 2.8-1.72c49.257-29.326 73.08 1.117 94.02 40.927z"
          />
          <path
            fill="url(#BG2)"
            d="M444.31 229.726c-46.27-80.956-94.1-157.228-149.043-45.344-7.516 14.384-12.995 42.337-25.267 42.337v-.142c-12.272 0-17.75-27.953-25.265-42.337C189.79 72.356 141.96 148.628 95.69 229.584c-3.483 6.106-6.828 11.932-9.69 16.996 106.038-67.127 97.11 135.667 184 137.278V384c86.891-1.611 77.962-204.405 184-137.28-2.86-5.062-6.206-10.888-9.69-16.994"
          />
          <path
            fill="url(#BG3)"
            d="M450 384c26.509 0 48-21.491 48-48s-21.491-48-48-48-48 21.491-48 48 21.491 48 48 48"
          />
        </g>
      </svg> */}
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 512 512"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          // transform="translate(0.000000,510.000000) scale(0.100000,-0.100000)"
          transform="translate(100,512) scale(0.100000,-0.100000)"
          fill={PRIMARY_MAIN}
          stroke="none"
        >
          <path
            d="M2925 4498 c-3 -7 -6 -92 -7 -188 l-3 -175 -582 -3 c-479 -2 -583 -5
-583 -16 0 -7 18 -41 40 -74 l39 -62 543 -2 543 -3 0 -125 0 -125 -277 -3
-278 -2 -57 29 c-83 41 -180 74 -198 68 -13 -6 -15 -78 -15 -621 l0 -616 23
-5 c12 -3 69 -5 127 -3 l105 3 5 45 5 45 213 3 c158 2 212 -1 212 -9 0 -49
-266 -319 -445 -452 -298 -221 -714 -432 -895 -454 -40 -5 -51 -2 -69 16 -46
46 -10 124 106 231 83 76 258 177 398 230 11 4 31 12 45 18 14 6 32 13 40 15
10 4 7 6 -6 6 -23 1 -165 -56 -294 -116 -158 -74 -357 -200 -432 -271 l-38
-36 0 -153 0 -153 48 0 c58 0 279 55 433 109 453 157 921 432 1174 690 33 34
63 61 67 61 4 0 9 -181 10 -402 l3 -403 130 0 130 0 3 453 c1 297 6 452 12
452 6 0 54 -46 108 -101 202 -211 467 -395 808 -560 186 -90 167 -88 213 -21
21 31 68 86 104 122 51 49 64 67 55 77 -6 7 -16 13 -22 13 -15 0 -252 89 -346
130 -201 87 -457 227 -597 326 -101 72 -213 163 -213 174 0 6 87 10 235 10
l235 0 0 -68 c0 -40 5 -73 12 -80 13 -13 202 -17 232 -6 14 5 16 59 16 500 l0
495 50 48 c30 29 50 57 50 70 0 25 -46 96 -82 126 -13 11 -49 42 -82 70 l-58
49 -36 -39 c-19 -22 -50 -57 -69 -78 l-34 -37 -292 2 -292 3 -3 116 c-2 89 1
119 11 127 10 9 173 13 572 14 l559 3 29 47 30 48 -29 37 c-48 64 -221 233
-237 233 -9 0 -44 -37 -78 -82 -34 -46 -73 -95 -87 -110 l-26 -28 -357 0
c-197 0 -364 3 -373 6 -12 5 -16 20 -16 63 0 65 4 73 47 95 48 24 73 56 73 91
0 37 -19 57 -74 80 -91 38 -301 60 -311 33z m-7 -1106 l3 -82 -248 2 -248 3
-3 74 c-2 53 1 77 10 83 7 4 119 7 248 5 l235 -2 3 -83z m730 76 c13 -13 16
-126 4 -146 -7 -9 -60 -12 -233 -10 l-224 3 -3 68 c-2 42 2 74 9 83 15 19 428
21 447 2z m-730 -391 l3 -77 -248 2 -248 3 -3 64 c-2 35 -1 70 2 78 5 11 50
13 248 11 l243 -3 3 -78z m731 69 c7 -9 11 -40 9 -78 l-3 -63 -230 0 -230 0
-3 69 c-2 49 1 72 10 77 7 5 108 9 224 9 170 0 214 -3 223 -14z"
          />
          <path
            d="M6006 4494 c-3 -9 -6 -110 -6 -225 l0 -209 -579 0 c-396 0 -582 -3
-586 -11 -7 -10 29 -112 48 -136 7 -10 276 -13 1282 -13 l1273 0 26 44 c14 25
26 53 26 64 0 22 -114 155 -209 245 -48 46 -65 56 -75 48 -7 -6 -51 -63 -97
-126 l-84 -115 -375 3 -375 2 -3 85 c-4 114 -1 122 53 144 53 21 85 58 85 96
0 55 -73 93 -210 110 -126 15 -187 13 -194 -6z"
          />
          <path
            d="M8764 4319 c-135 -107 -333 -183 -594 -227 l-105 -17 -3 -99 c-2 -73
1 -102 10 -108 7 -4 96 -8 198 -8 166 0 188 -2 217 -20 18 -11 36 -32 40 -47
5 -15 8 -392 8 -838 0 -767 -1 -812 -18 -847 -28 -55 -79 -76 -202 -83 -55 -3
-122 -8 -150 -10 -27 -2 -53 -4 -57 -5 -5 0 -8 -50 -8 -110 l0 -110 568 2 567
3 3 99 c1 55 -2 104 -7 109 -5 5 -71 14 -147 19 -155 10 -213 30 -245 82 -18
30 -19 72 -19 1133 0 624 -4 1104 -9 1107 -5 3 -26 -8 -47 -25z"
          />
          <path
            d="M9832 4218 c-17 -17 -16 -192 1 -205 6 -6 53 -15 102 -22 102 -13
174 -44 192 -81 10 -19 13 -228 13 -910 0 -823 -1 -887 -18 -920 -24 -48 -63
-66 -172 -80 -52 -7 -102 -15 -110 -20 -21 -10 -27 -192 -7 -209 11 -8 135
-11 468 -9 l454 3 3 90 c4 124 0 135 -46 135 -58 0 -202 28 -227 44 -12 8 -29
30 -39 50 -14 31 -16 77 -14 408 l3 373 135 0 c117 -1 145 -4 200 -24 170 -62
267 -176 431 -506 78 -156 141 -300 205 -468 30 -78 41 -98 60 -103 13 -3 164
-4 336 -1 340 5 388 11 544 73 388 154 644 530 644 946 0 112 -11 175 -48 273
-21 57 -39 83 -97 140 -58 58 -81 74 -135 93 -36 12 -83 22 -105 22 -84 0
-134 -64 -101 -128 17 -33 34 -42 80 -42 32 0 48 11 85 59 l18 25 56 -28 c62
-30 125 -94 155 -159 35 -76 45 -142 40 -271 -4 -101 -10 -136 -35 -208 -44
-127 -106 -230 -193 -318 -90 -91 -163 -141 -276 -186 -122 -49 -213 -64 -394
-64 -167 0 -219 10 -295 55 -58 33 -79 65 -175 255 -161 318 -248 463 -339
562 -28 31 -51 58 -51 61 0 3 24 13 53 23 287 93 447 318 447 627 -1 168 -48
300 -145 406 -142 153 -304 219 -590 240 -203 16 -1097 15 -1113 -1z m1086
-238 c274 -30 420 -141 454 -344 28 -169 -34 -322 -164 -406 -126 -82 -271
-110 -565 -110 l-213 0 0 373 c0 413 1 422 66 459 60 35 243 47 422 28z"
          />
          <path
            d="M6839 3705 l-62 -75 -619 0 -619 0 -50 25 c-81 42 -202 86 -211 77
-5 -5 -7 -224 -6 -488 l3 -479 128 -3 c97 -2 128 0 131 10 3 7 7 25 10 41 l6
27 243 -2 242 -3 3 -78 c2 -57 -1 -78 -10 -81 -59 -16 -164 -53 -217 -77 -35
-16 -68 -29 -73 -29 -5 0 -50 25 -100 55 -50 30 -95 55 -99 55 -5 0 -47 -59
-94 -131 -47 -72 -120 -174 -162 -227 -96 -123 -283 -309 -393 -391 -47 -35
-87 -68 -88 -73 -6 -14 77 -118 95 -118 32 0 252 131 372 221 35 27 69 49 75
49 7 0 18 -21 25 -47 17 -57 76 -118 128 -132 21 -6 92 -23 158 -37 190 -41
253 -77 270 -156 4 -18 13 -36 19 -40 15 -10 129 1 189 18 66 18 112 48 137
90 46 73 50 115 50 522 0 424 -6 394 71 367 52 -19 118 -59 223 -138 185 -140
324 -282 443 -453 90 -129 110 -151 149 -164 51 -17 119 -4 157 30 28 25 32
34 35 99 4 65 1 78 -28 138 -55 112 -223 265 -420 383 -111 66 -354 186 -416
205 -26 8 -85 17 -131 21 l-83 7 0 59 0 58 228 -2 227 -3 5 -70 5 -70 130 0
130 0 3 368 2 367 49 35 c79 57 76 94 -16 182 -65 63 -155 133 -171 133 -6 0
-39 -34 -73 -75z m-64 -465 l0 -235 -609 -3 c-481 -2 -611 0 -618 10 -12 19
-10 426 2 449 11 19 26 19 618 17 l607 -3 0 -235z m-740 -1001 l0 -374 -90 -3
c-89 -4 -256 9 -388 29 -73 12 -116 38 -142 90 -29 55 -21 79 56 154 39 39 99
104 134 146 64 75 65 76 121 82 93 10 131 67 84 127 -28 35 -25 45 18 66 45
22 183 64 197 60 6 -2 10 -136 10 -377z"
          />
          <path d="M2043 2303 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z" />
          <path d="M1983 2283 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z" />
          <path
            d="M3220 1151 c-133 -28 -210 -132 -210 -279 0 -123 57 -215 159 -260
56 -24 186 -22 236 5 163 85 205 309 85 446 -22 25 -59 54 -83 65 -48 23 -136
34 -187 23z m121 -72 c51 -17 97 -55 119 -99 27 -53 27 -156 1 -208 -72 -140
-258 -151 -346 -21 -26 39 -30 52 -30 119 0 64 4 82 28 122 47 81 140 116 228
87z"
          />
          <path
            d="M5990 1151 c-130 -28 -214 -135 -214 -276 0 -55 6 -82 26 -125 31
-66 53 -90 115 -127 43 -25 58 -28 133 -28 99 1 145 19 203 84 53 59 69 103
69 197 0 67 -4 89 -25 131 -29 56 -80 106 -132 127 -43 18 -130 27 -175 17z
m156 -88 c21 -11 53 -39 69 -62 28 -39 30 -50 30 -126 0 -76 -3 -87 -30 -127
-93 -134 -287 -111 -351 41 -30 72 -7 198 45 245 63 58 159 69 237 29z"
          />
          <path
            d="M9100 1149 c-93 -19 -180 -108 -200 -206 -29 -138 27 -264 144 -326
51 -27 181 -29 237 -5 102 45 159 137 159 260 0 85 -18 141 -65 196 -56 67
-174 102 -275 81z m166 -86 c24 -12 51 -38 69 -68 26 -42 30 -58 30 -119 0
-127 -67 -206 -183 -214 -86 -6 -153 32 -193 110 -26 52 -26 155 1 208 50 98
175 135 276 83z"
          />
          <path
            d="M10130 1151 c-154 -35 -247 -186 -210 -343 15 -60 30 -88 74 -136 54
-59 100 -76 197 -76 74 0 91 3 130 26 96 57 143 139 143 253 0 118 -62 219
-158 259 -44 19 -131 27 -176 17z m156 -88 c108 -55 139 -236 57 -334 -76 -90
-230 -90 -306 0 -62 74 -63 217 -2 290 59 69 166 88 251 44z"
          />
          <path
            d="M7435 1137 c-32 -10 -40 -17 -40 -37 0 -17 6 -26 20 -29 11 -2 23 -8
27 -15 4 -6 8 -109 8 -229 0 -192 2 -218 16 -224 9 -3 24 -2 33 4 14 9 16 42
16 274 0 298 5 282 -80 256z"
          />
          <path
            d="M2124 1125 c-14 -35 3 -44 88 -47 l83 -3 5 -229 c4 -196 7 -230 21
-239 9 -6 24 -7 33 -4 14 6 16 32 16 229 0 123 4 228 8 235 5 7 40 13 92 15
83 3 85 4 85 28 l0 25 -213 3 c-183 2 -213 0 -218 -13z"
          />
          <path
            d="M4057 1133 c-4 -3 -7 -120 -7 -258 0 -270 3 -285 50 -270 18 6 20 14
20 110 0 57 3 106 8 109 4 2 61 -45 127 -105 66 -60 130 -112 142 -115 24 -6
63 3 63 15 0 4 -39 43 -87 87 -48 43 -110 100 -138 126 l-50 46 118 119 c64
66 117 124 117 131 0 7 -14 12 -33 12 -28 0 -47 -14 -137 -105 -58 -58 -110
-105 -117 -105 -10 0 -13 27 -13 105 l0 105 -28 0 c-16 0 -32 -3 -35 -7z"
          />
          <path
            d="M4874 1125 c3 -9 46 -81 96 -160 l90 -144 0 -99 c0 -106 10 -130 49
-117 17 5 20 17 23 113 l4 107 92 145 c50 80 92 151 92 158 0 7 -13 12 -33 12
-30 0 -36 -7 -103 -112 -39 -62 -78 -114 -85 -115 -8 -2 -43 45 -84 112 l-69
115 -39 0 c-31 0 -38 -3 -33 -15z"
          />
          <path
            d="M8062 873 c3 -252 4 -268 22 -271 32 -7 41 16 46 116 l5 97 37 3 c36
3 40 0 85 -64 104 -146 107 -149 139 -149 44 0 37 27 -34 127 l-61 88 34 25
c37 29 65 88 65 138 0 49 -39 118 -80 139 -28 14 -58 18 -148 18 l-112 0 2
-267z m201 196 c80 -22 88 -137 13 -173 -34 -16 -101 -21 -130 -10 -13 5 -16
23 -16 93 0 48 3 91 7 94 10 10 83 8 126 -4z"
          />
          <path
            d="M10967 1133 c-4 -3 -7 -122 -7 -264 0 -229 2 -258 16 -263 9 -4 25
-4 35 -1 17 6 19 19 19 163 0 154 9 211 25 161 4 -13 20 -51 35 -84 57 -124
77 -171 84 -191 10 -32 42 -57 64 -50 11 4 30 32 46 69 95 214 120 267 127
267 5 0 10 -73 11 -161 3 -136 6 -163 19 -172 9 -6 24 -7 33 -4 14 6 16 35 16
272 l0 266 -37 -3 c-36 -3 -37 -4 -85 -113 -27 -60 -61 -135 -75 -165 -14 -30
-32 -72 -40 -92 -8 -21 -19 -38 -25 -38 -5 0 -21 28 -37 63 -15 34 -37 82 -48
107 -11 25 -37 83 -58 130 -20 47 -41 91 -47 98 -11 13 -60 17 -71 5z"
          />
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
