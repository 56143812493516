import * as Yup from 'yup';

const propertyAnnualItem = Yup.object().shape({
  value: Yup.number().min(0).required(),
  isCyclical: Yup.bool().optional(),
  yearInterval: Yup.number().min(0).default(0),
});

const otherItem = Yup.object().shape({
  name: Yup.string().required(),
  value: Yup.number().min(0).required(),
});

const loanEarlyRepayment = Yup.object().shape({
  date: Yup.string().required(),
  amount: Yup.number().min(0).required(),
});

const railwayInfoItem = Yup.object()
  .shape({
    code: Yup.string().optional(),
    name: Yup.string().optional(),
  })
  .nullable();

export const defaultPropertySchema = Yup.object().shape({
  title: Yup.string().required(),
  address: Yup.object().shape({
    postalCode: Yup.string().required(),
    principal: Yup.string().required(),
    city: Yup.string().required(),
    additionalInfo: Yup.string().optional(),
    roomNumber: Yup.string().optional(),
  }),
  railwayInfo: Yup.object().shape({
    // company: railwayInfoItem.optional(),
    line: railwayInfoItem.optional(),
    station: railwayInfoItem.optional(), // in case of 新幹線, there is no station selectable
  }),
  walkingMinutesFromStation: Yup.string().required(),
  builtAt: Yup.date().required(),
  numberOfSquareMeters: Yup.number().min(0).required(),
  numberOfRooms: Yup.number().integer().min(1).required(),
  floorPosition: Yup.number().integer().min(0).optional(), // Added afterwards so it's optional
  cover: Yup.mixed().optional(),
  description: Yup.string().optional(),
  basicSettings: Yup.object().shape({
    operationStartedAt: Yup.date().required(),
    operationYears: Yup.number().min(1).required(),
    propertyPrice: Yup.number().min(0).required(),
    initialPayment: Yup.number().min(0).required(),
    rentRevenue: Yup.number().min(0).required(),
    isRentRevenueGuaranteed: Yup.bool().required(),
    rentCollectionServiceFee: Yup.number().min(0).required(),
    managementCost: Yup.number().min(0).required(),
    repairReserve: Yup.number().min(0).required(),
    bankName: Yup.string().required(),
    borrowingAmount: Yup.number().min(0).required(),
    annualInterestRate: Yup.number().min(0).required(),
    borrowingPeriodInYears: Yup.number().min(0).required(),
    otherItems: Yup.array().max(3).of(otherItem).optional(),
  }),
  loanEarlyRepaymentSettings: Yup.object().shape({
    loanEarlyRepaymentType: Yup.string().required(),
    loanEarlyRepayments: Yup.array().max(50).of(loanEarlyRepayment).optional(),
  }),
  advancedSettings: Yup.object().shape({
    propertyAcquisitionTax: propertyAnnualItem,
    otherPayments: propertyAnnualItem,
    fireInsurance: propertyAnnualItem,
    bathroomDryer: propertyAnnualItem,
    airConditioner: propertyAnnualItem,
    boiler: propertyAnnualItem,
    wallpaperReplacement: propertyAnnualItem,
    floorReplacement: propertyAnnualItem,
  }),
  volatilitySettings: Yup.object().shape({
    rentChangeRate: Yup.number().required(),
    sellingPriceChangeRate: Yup.number().required(),
    propertyPriceDropRate: Yup.number().required(),
    vacancyRate: Yup.number().min(0).required(),
  }),
});
