import { DefaultApi, AdminApi } from "src/api-client";
import axios, { AxiosRequestConfig } from "axios";

// config
import { HOST_API } from "../config";

// ----------------------------------------------------------------------

export const axiosOpenApiInstance = new DefaultApi({
  basePath: HOST_API,
  isJsonMime: (mime: string) => mime === "application/json",
});

export const axiosAdminApiInstance = new AdminApi({
  basePath: HOST_API,
  isJsonMime: (mime: string) => mime === "application/json",
});

// globalAxios config
axios.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = localStorage.getItem("firebase-user-id-token") || "";
  if (config && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default axiosOpenApiInstance;
