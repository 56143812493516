export const FLOOR_POSITION_OPTIONS = [
  {
    label: '地下1階',
    value: 0,
  },
  {
    label: '1階',
    value: 1,
  },
  {
    label: '2階以上',
    value: 2,
  },
];
