// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Autocomplete, TextField } from '@mui/material';

// ----------------------------------------------------------------------

interface IProps {
  name: string;
  label: string;
  options: Array<{ label: string; value: any }>;
  value: any;
  onChange?: (event: any, value: any) => void;
  disabled?: boolean;
}

export default function RHFSearchableSelect({
  name,
  options,
  label,
  value,
  onChange,
  disabled,
  ...other
}: IProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          options={options}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.value === value.value} // valueの比較を修正
          value={options.find((option) => option.value === value) || null} // 現在の選択を正確に取得
          onChange={(event, selectedValue) => {
            field.onChange(selectedValue?.value || ''); // フォームの値として設定
            if (onChange) {
              onChange(event, selectedValue?.value); // 外部からのonChange呼び出し
            }
          }}
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              fullWidth
              error={!!error}
              helperText={error?.message}
              InputLabelProps={{ shrink: true }}
              {...other}
            />
          )}
        />
      )}
    />
  );
}