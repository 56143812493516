// components
import Iconify from 'src/components/Iconify';
import { PATH_DASHBOARD, PATH_ADMIN } from 'src/routes/paths';
import SvgIconStyle from '../../../components/SvgIconStyle';
import { UserDtoRolesEnum } from 'src/api-client';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

export const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  question: <Iconify icon="eva:question-mark-outline" />,
  externalLink: <Iconify icon="eva:external-link-outline" />,
  info: <Iconify icon="eva:info-outline" />,
};

const navConfig = (roles: UserDtoRolesEnum[]) => [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'ダッシュボード',
    items: [
      // { title: 'One', path: '/dashboard/one', icon: ICONS.analytics }, // 分析
      // { title: 'Time Series', path: '/dashboard/two', icon: ICONS.banking },
      // { title: 'Settings', path: '/dashboard/three', icon: ICONS.booking },
      { title: 'みんなの物件', path: PATH_DASHBOARD.properties.root, icon: ICONS.banking },
      { title: '自分の物件', path: PATH_DASHBOARD.myProperties.root, icon: ICONS.banking },
      { title: '免責事項', path: PATH_DASHBOARD.disclaimer, icon: ICONS.info },
      {
        title: 'プライバシーポリシー',
        path: 'https://tokyo-1r.com/privacy-policy/',
        icon: ICONS.externalLink,
      },
      { title: 'お問い合わせ', path: 'https://tokyo-1r.com/otoiawase/', icon: ICONS.externalLink },
      {
        title: '運営情報',
        path: 'https://tokyo-1r.com/%e9%81%8b%e5%96%b6%e6%83%85%e5%a0%b1/',
        icon: ICONS.externalLink,
      },
      // { title: 'My Property', path: '/dashboard/property', icon: ICONS.user },
      // { title: 'Settings', path: '/dashboard/settings', icon: ICONS.booking },
      // { title: 'Settings', path: '/dashboard/settings', icon: ICONS.booking },
    ],
  },
  ...(roles.includes('admin') ? [{
    subheader: 'アドミン機能',
    items: [
      { title: '登録者一覧', path: PATH_ADMIN.users.root, icon: ICONS.user },
      { title: '物件一覧', path: PATH_ADMIN.users.properties, icon: ICONS.banking },
    ]
  }] : [])

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'management',
  //   items: [
  //     {
  //       title: 'user',
  //       path: '/dashboard/user',
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'Four', path: '/dashboard/user/four' },
  //         { title: 'Five', path: '/dashboard/user/five' },
  //         { title: 'Six', path: '/dashboard/user/six' },
  //       ],
  //     },
  //   ],
  // },
];

export default navConfig;
