import {
  format,
  getTime,
  formatDistanceToNow,
  differenceInYears,
  differenceInMonths,
} from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date: Date | string | number) {
  return format(new Date(date), 'yyyy年MM月dd日');
}

export function fYearMonth(date: Date | string | number) {
  return format(new Date(date), 'yyyy年MM月');
}

export function fYear(date: Date | string | number) {
  return format(new Date(date), 'yyyy年');
}

export function fDateTime(date: Date | string | number) {
  return format(new Date(date), 'dd MMM yyyy p');
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date: Date | string | number, addSuffix = true) {
  return formatDistanceToNow(new Date(date), {
    addSuffix,
  });
}

/**
 *
 * @param date
 * @returns 1 if now is 2022 and date is 2021
 */
export function fToNowInYears(date: Date | string | number) {
  return differenceInYears(new Date(), new Date(date));
}

/**
 *
 * @param date
 * @returns 1 if now is 2022/11 and date is 2022/10
 */
export function fToNowInMonths(date: Date | string | number) {
  return differenceInMonths(new Date(), new Date(date));
}
