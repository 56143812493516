/* tslint:disable */
/* eslint-disable */
/**
 * Tokyo 1R Simulator API
 * The api for Tokyo 1R Simulator
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CreateMyPropertyDto
 */
export interface CreateMyPropertyDto {
    /**
     * 
     * @type {string}
     * @memberof CreateMyPropertyDto
     */
    'title': string;
    /**
     * 
     * @type {PropertyAddressDto}
     * @memberof CreateMyPropertyDto
     */
    'address': PropertyAddressDto;
    /**
     * 
     * @type {string}
     * @memberof CreateMyPropertyDto
     */
    'builtAt': string;
    /**
     * 
     * @type {number}
     * @memberof CreateMyPropertyDto
     */
    'numberOfSquareMeters': number;
    /**
     * 
     * @type {number}
     * @memberof CreateMyPropertyDto
     */
    'numberOfRooms'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateMyPropertyDto
     */
    'floorPosition'?: number;
    /**
     * 
     * @type {PropertyRailwayInfoDto}
     * @memberof CreateMyPropertyDto
     */
    'railwayInfo'?: PropertyRailwayInfoDto;
    /**
     * 
     * @type {string}
     * @memberof CreateMyPropertyDto
     */
    'walkingMinutesFromStation'?: CreateMyPropertyDtoWalkingMinutesFromStationEnum;
    /**
     * 
     * @type {PropertyOwnerDto}
     * @memberof CreateMyPropertyDto
     */
    'owner': PropertyOwnerDto;
    /**
     * 
     * @type {string}
     * @memberof CreateMyPropertyDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMyPropertyDto
     */
    'cover'?: string;
    /**
     * 
     * @type {PropertyBasicSettingsDto}
     * @memberof CreateMyPropertyDto
     */
    'basicSettings': PropertyBasicSettingsDto;
    /**
     * 
     * @type {PropertyAdvancedSettingsDto}
     * @memberof CreateMyPropertyDto
     */
    'advancedSettings'?: PropertyAdvancedSettingsDto;
    /**
     * 
     * @type {LoanEarlyRepaymentSettingsDto}
     * @memberof CreateMyPropertyDto
     */
    'loanEarlyRepaymentSettings'?: LoanEarlyRepaymentSettingsDto;
    /**
     * 
     * @type {PropertyVolatilitySettingsDto}
     * @memberof CreateMyPropertyDto
     */
    'volatilitySettings'?: PropertyVolatilitySettingsDto;
}

export const CreateMyPropertyDtoWalkingMinutesFromStationEnum = {
    OneToFourMinutes: 'one-to-four-minutes',
    FiveToTenMinutes: 'five-to-ten-minutes',
    ElevenToTwelveMinutes: 'eleven-to-twelve-minutes',
    MoreThanOrEqualToThirteenMinutes: 'more-than-or-equal-to-thirteen-minutes'
} as const;

export type CreateMyPropertyDtoWalkingMinutesFromStationEnum = typeof CreateMyPropertyDtoWalkingMinutesFromStationEnum[keyof typeof CreateMyPropertyDtoWalkingMinutesFromStationEnum];

/**
 * 
 * @export
 * @interface EstimationResult
 */
export interface EstimationResult {
    /**
     * 
     * @type {number}
     * @memberof EstimationResult
     */
    'buildingAge': number;
    /**
     * 
     * @type {number}
     * @memberof EstimationResult
     */
    'minPurchasePrice': number | null;
    /**
     * 
     * @type {number}
     * @memberof EstimationResult
     */
    'maxPurchasePrice': number | null;
    /**
     * 
     * @type {number}
     * @memberof EstimationResult
     */
    'minSellingPrice': number | null;
    /**
     * 
     * @type {number}
     * @memberof EstimationResult
     */
    'maxSellingPrice': number | null;
}
/**
 * 
 * @export
 * @interface GetAddressInfoDto
 */
export interface GetAddressInfoDto {
    /**
     * 
     * @type {string}
     * @memberof GetAddressInfoDto
     */
    'address1': string;
    /**
     * 
     * @type {string}
     * @memberof GetAddressInfoDto
     */
    'address2': string;
    /**
     * 
     * @type {string}
     * @memberof GetAddressInfoDto
     */
    'address3': string;
}
/**
 * 
 * @export
 * @interface GetLoanPaymentsDto
 */
export interface GetLoanPaymentsDto {
    /**
     * 
     * @type {Array<LoanPaymentDto>}
     * @memberof GetLoanPaymentsDto
     */
    'data': Array<LoanPaymentDto>;
    /**
     * 
     * @type {number}
     * @memberof GetLoanPaymentsDto
     */
    'totalItems': number;
}
/**
 * 
 * @export
 * @interface GetMyPropertiesResponseDto
 */
export interface GetMyPropertiesResponseDto {
    /**
     * 
     * @type {Array<PropertyDocument>}
     * @memberof GetMyPropertiesResponseDto
     */
    'data': Array<PropertyDocument>;
    /**
     * 
     * @type {number}
     * @memberof GetMyPropertiesResponseDto
     */
    'totalItems': number;
}
/**
 * 
 * @export
 * @interface GetPropertiesResponseDto
 */
export interface GetPropertiesResponseDto {
    /**
     * 
     * @type {Array<PropertyDocument>}
     * @memberof GetPropertiesResponseDto
     */
    'data': Array<PropertyDocument>;
    /**
     * 
     * @type {number}
     * @memberof GetPropertiesResponseDto
     */
    'totalItems': number;
}
/**
 * 
 * @export
 * @interface GetPropertySimulateResponseDto
 */
export interface GetPropertySimulateResponseDto {
    /**
     * 
     * @type {GetSimulateResultResponseDto}
     * @memberof GetPropertySimulateResponseDto
     */
    'simulateResult': GetSimulateResultResponseDto;
    /**
     * 
     * @type {PropertyDocument}
     * @memberof GetPropertySimulateResponseDto
     */
    'property': PropertyDocument;
}
/**
 * 
 * @export
 * @interface GetRailwayCompaniesResponseDto
 */
export interface GetRailwayCompaniesResponseDto {
    /**
     * 
     * @type {Array<RailwayCompanyDocument>}
     * @memberof GetRailwayCompaniesResponseDto
     */
    'data': Array<RailwayCompanyDocument>;
    /**
     * 
     * @type {number}
     * @memberof GetRailwayCompaniesResponseDto
     */
    'totalItems': number;
}
/**
 * 
 * @export
 * @interface GetRailwayLinesResponseDto
 */
export interface GetRailwayLinesResponseDto {
    /**
     * 
     * @type {Array<RailwayLineDocument>}
     * @memberof GetRailwayLinesResponseDto
     */
    'data': Array<RailwayLineDocument>;
    /**
     * 
     * @type {number}
     * @memberof GetRailwayLinesResponseDto
     */
    'totalItems': number;
}
/**
 * 
 * @export
 * @interface GetRailwayStationsResponseDto
 */
export interface GetRailwayStationsResponseDto {
    /**
     * 
     * @type {Array<RailwayStationDocument>}
     * @memberof GetRailwayStationsResponseDto
     */
    'data': Array<RailwayStationDocument>;
    /**
     * 
     * @type {number}
     * @memberof GetRailwayStationsResponseDto
     */
    'totalItems': number;
}
/**
 * 
 * @export
 * @interface GetSellingEstimationResponseDto
 */
export interface GetSellingEstimationResponseDto {
    /**
     * 
     * @type {PropertyDocument}
     * @memberof GetSellingEstimationResponseDto
     */
    'property': PropertyDocument;
    /**
     * 
     * @type {EstimationResult}
     * @memberof GetSellingEstimationResponseDto
     */
    'estimationResult': EstimationResult;
}
/**
 * 
 * @export
 * @interface GetSimulateResultResponseDto
 */
export interface GetSimulateResultResponseDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSimulateResultResponseDto
     */
    'xAxisLabels': Array<string>;
    /**
     * 
     * @type {TotalWithItems}
     * @memberof GetSimulateResultResponseDto
     */
    'monthlyCashIn': TotalWithItems;
    /**
     * 
     * @type {TotalWithItems}
     * @memberof GetSimulateResultResponseDto
     */
    'monthlyCashOut': TotalWithItems;
    /**
     * 
     * @type {Array<number>}
     * @memberof GetSimulateResultResponseDto
     */
    'monthlyCashNet': Array<number>;
    /**
     * 
     * @type {TotalWithItems}
     * @memberof GetSimulateResultResponseDto
     */
    'annualCashIn': TotalWithItems;
    /**
     * 
     * @type {TotalWithItems}
     * @memberof GetSimulateResultResponseDto
     */
    'annualCashOut': TotalWithItems;
    /**
     * 
     * @type {Array<number>}
     * @memberof GetSimulateResultResponseDto
     */
    'annualCashNet': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof GetSimulateResultResponseDto
     */
    'accumulatedAnnualAllCashNet': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof GetSimulateResultResponseDto
     */
    'estimatedSellingPrice': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof GetSimulateResultResponseDto
     */
    'remainingDebtAtEndOfYear': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof GetSimulateResultResponseDto
     */
    'estimatedRevenue': Array<number>;
}
/**
 * 
 * @export
 * @interface LineCallbackBodyDto
 */
export interface LineCallbackBodyDto {
    /**
     * 
     * @type {string}
     * @memberof LineCallbackBodyDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof LineCallbackBodyDto
     */
    'state': string;
    /**
     * 
     * @type {boolean}
     * @memberof LineCallbackBodyDto
     */
    'friendship_status_changed'?: boolean;
}
/**
 * 
 * @export
 * @interface LoanEarlyRepayment
 */
export interface LoanEarlyRepayment {
    /**
     * 
     * @type {string}
     * @memberof LoanEarlyRepayment
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof LoanEarlyRepayment
     */
    'amount': number;
}
/**
 * 
 * @export
 * @interface LoanEarlyRepaymentDto
 */
export interface LoanEarlyRepaymentDto {
    /**
     * 
     * @type {string}
     * @memberof LoanEarlyRepaymentDto
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof LoanEarlyRepaymentDto
     */
    'amount': number;
}
/**
 * 
 * @export
 * @interface LoanEarlyRepaymentSettingsDto
 */
export interface LoanEarlyRepaymentSettingsDto {
    /**
     * 
     * @type {string}
     * @memberof LoanEarlyRepaymentSettingsDto
     */
    'loanEarlyRepaymentType': LoanEarlyRepaymentSettingsDtoLoanEarlyRepaymentTypeEnum;
    /**
     * 
     * @type {Array<LoanEarlyRepaymentDto>}
     * @memberof LoanEarlyRepaymentSettingsDto
     */
    'loanEarlyRepayments': Array<LoanEarlyRepaymentDto>;
}

export const LoanEarlyRepaymentSettingsDtoLoanEarlyRepaymentTypeEnum = {
    AmountReduction: 'amount-reduction',
    PeriodShortening: 'period-shortening'
} as const;

export type LoanEarlyRepaymentSettingsDtoLoanEarlyRepaymentTypeEnum = typeof LoanEarlyRepaymentSettingsDtoLoanEarlyRepaymentTypeEnum[keyof typeof LoanEarlyRepaymentSettingsDtoLoanEarlyRepaymentTypeEnum];

/**
 * 
 * @export
 * @interface LoanPaymentDto
 */
export interface LoanPaymentDto {
    /**
     * 
     * @type {string}
     * @memberof LoanPaymentDto
     */
    'paymentDate': string;
    /**
     * 
     * @type {number}
     * @memberof LoanPaymentDto
     */
    'totalPayment': number;
    /**
     * 
     * @type {number}
     * @memberof LoanPaymentDto
     */
    'principalPayment': number;
    /**
     * 
     * @type {number}
     * @memberof LoanPaymentDto
     */
    'interestPayment': number;
    /**
     * 
     * @type {number}
     * @memberof LoanPaymentDto
     */
    'remainingDebt': number;
    /**
     * 
     * @type {number}
     * @memberof LoanPaymentDto
     */
    'earlyRepayment'?: number;
}
/**
 * 
 * @export
 * @interface OtherItem
 */
export interface OtherItem {
    /**
     * 
     * @type {string}
     * @memberof OtherItem
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof OtherItem
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface PropertyAddress
 */
export interface PropertyAddress {
    /**
     * 
     * @type {string}
     * @memberof PropertyAddress
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof PropertyAddress
     */
    'principal': string;
    /**
     * 
     * @type {string}
     * @memberof PropertyAddress
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof PropertyAddress
     */
    'additionalInfo'?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyAddress
     */
    'roomNumber'?: string;
}
/**
 * 
 * @export
 * @interface PropertyAddressDto
 */
export interface PropertyAddressDto {
    /**
     * 
     * @type {string}
     * @memberof PropertyAddressDto
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof PropertyAddressDto
     */
    'principal': string;
    /**
     * 
     * @type {string}
     * @memberof PropertyAddressDto
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof PropertyAddressDto
     */
    'additionalInfo'?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyAddressDto
     */
    'roomNumber'?: string;
}
/**
 * 
 * @export
 * @interface PropertyAdvancedSettings
 */
export interface PropertyAdvancedSettings {
    /**
     * 
     * @type {PropertyAnnualItem}
     * @memberof PropertyAdvancedSettings
     */
    'propertyAcquisitionTax'?: PropertyAnnualItem;
    /**
     * 
     * @type {PropertyAnnualItem}
     * @memberof PropertyAdvancedSettings
     */
    'otherPayments'?: PropertyAnnualItem;
    /**
     * 
     * @type {PropertyAnnualItem}
     * @memberof PropertyAdvancedSettings
     */
    'propertyTax'?: PropertyAnnualItem;
    /**
     * 
     * @type {PropertyAnnualItem}
     * @memberof PropertyAdvancedSettings
     */
    'fireInsurance'?: PropertyAnnualItem;
    /**
     * 
     * @type {PropertyAnnualItem}
     * @memberof PropertyAdvancedSettings
     */
    'bathroomDryer'?: PropertyAnnualItem;
    /**
     * 
     * @type {PropertyAnnualItem}
     * @memberof PropertyAdvancedSettings
     */
    'airConditioner'?: PropertyAnnualItem;
    /**
     * 
     * @type {PropertyAnnualItem}
     * @memberof PropertyAdvancedSettings
     */
    'boiler'?: PropertyAnnualItem;
    /**
     * 
     * @type {PropertyAnnualItem}
     * @memberof PropertyAdvancedSettings
     */
    'wallpaperReplacement'?: PropertyAnnualItem;
    /**
     * 
     * @type {PropertyAnnualItem}
     * @memberof PropertyAdvancedSettings
     */
    'floorReplacement'?: PropertyAnnualItem;
}
/**
 * 
 * @export
 * @interface PropertyAdvancedSettingsDto
 */
export interface PropertyAdvancedSettingsDto {
    /**
     * 
     * @type {PropertyAnnualItemSetting}
     * @memberof PropertyAdvancedSettingsDto
     */
    'propertyAcquisitionTax'?: PropertyAnnualItemSetting;
    /**
     * 
     * @type {PropertyAnnualItemSetting}
     * @memberof PropertyAdvancedSettingsDto
     */
    'otherPayments'?: PropertyAnnualItemSetting;
    /**
     * 
     * @type {PropertyAnnualItemSetting}
     * @memberof PropertyAdvancedSettingsDto
     */
    'propertyTax'?: PropertyAnnualItemSetting;
    /**
     * 
     * @type {PropertyAnnualItemSetting}
     * @memberof PropertyAdvancedSettingsDto
     */
    'fireInsurance'?: PropertyAnnualItemSetting;
    /**
     * 
     * @type {PropertyAnnualItemSetting}
     * @memberof PropertyAdvancedSettingsDto
     */
    'bathroomDryer'?: PropertyAnnualItemSetting;
    /**
     * 
     * @type {PropertyAnnualItemSetting}
     * @memberof PropertyAdvancedSettingsDto
     */
    'airConditioner'?: PropertyAnnualItemSetting;
    /**
     * 
     * @type {PropertyAnnualItemSetting}
     * @memberof PropertyAdvancedSettingsDto
     */
    'boiler'?: PropertyAnnualItemSetting;
    /**
     * 
     * @type {PropertyAnnualItemSetting}
     * @memberof PropertyAdvancedSettingsDto
     */
    'wallpaperReplacement'?: PropertyAnnualItemSetting;
    /**
     * 
     * @type {PropertyAnnualItemSetting}
     * @memberof PropertyAdvancedSettingsDto
     */
    'floorReplacement'?: PropertyAnnualItemSetting;
}
/**
 * 
 * @export
 * @interface PropertyAnnualItem
 */
export interface PropertyAnnualItem {
    /**
     * 
     * @type {number}
     * @memberof PropertyAnnualItem
     */
    'value': number;
    /**
     * 
     * @type {number}
     * @memberof PropertyAnnualItem
     */
    'yearInterval': number;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyAnnualItem
     */
    'isCyclical': boolean;
}
/**
 * 
 * @export
 * @interface PropertyAnnualItemSetting
 */
export interface PropertyAnnualItemSetting {
    /**
     * 
     * @type {number}
     * @memberof PropertyAnnualItemSetting
     */
    'value': number;
    /**
     * 
     * @type {number}
     * @memberof PropertyAnnualItemSetting
     */
    'yearInterval': number;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyAnnualItemSetting
     */
    'isCyclical': boolean;
}
/**
 * 
 * @export
 * @interface PropertyBasicSettings
 */
export interface PropertyBasicSettings {
    /**
     * 
     * @type {string}
     * @memberof PropertyBasicSettings
     */
    'operationStartedAt': string;
    /**
     * 
     * @type {number}
     * @memberof PropertyBasicSettings
     */
    'operationYears': number;
    /**
     * 
     * @type {number}
     * @memberof PropertyBasicSettings
     */
    'propertyPrice': number;
    /**
     * 
     * @type {number}
     * @memberof PropertyBasicSettings
     */
    'initialPayment': number;
    /**
     * 
     * @type {number}
     * @memberof PropertyBasicSettings
     */
    'rentRevenue': number;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyBasicSettings
     */
    'isRentRevenueGuaranteed': boolean;
    /**
     * 
     * @type {number}
     * @memberof PropertyBasicSettings
     */
    'rentCollectionServiceFee': number;
    /**
     * 
     * @type {number}
     * @memberof PropertyBasicSettings
     */
    'managementCost': number;
    /**
     * 
     * @type {number}
     * @memberof PropertyBasicSettings
     */
    'repairReserve': number;
    /**
     * 
     * @type {string}
     * @memberof PropertyBasicSettings
     */
    'bankName'?: string;
    /**
     * 
     * @type {number}
     * @memberof PropertyBasicSettings
     */
    'borrowingAmount': number;
    /**
     * 
     * @type {number}
     * @memberof PropertyBasicSettings
     */
    'annualInterestRate': number;
    /**
     * 
     * @type {number}
     * @memberof PropertyBasicSettings
     */
    'borrowingPeriodInYears': number;
    /**
     * 
     * @type {number}
     * @memberof PropertyBasicSettings
     */
    'monthlyLoanPaymentAmount': number;
    /**
     * 
     * @type {Array<OtherItem>}
     * @memberof PropertyBasicSettings
     */
    'otherItems'?: Array<OtherItem>;
}
/**
 * 
 * @export
 * @interface PropertyBasicSettingsDto
 */
export interface PropertyBasicSettingsDto {
    /**
     * 
     * @type {string}
     * @memberof PropertyBasicSettingsDto
     */
    'operationStartedAt': string;
    /**
     * 
     * @type {number}
     * @memberof PropertyBasicSettingsDto
     */
    'operationYears': number;
    /**
     * 
     * @type {number}
     * @memberof PropertyBasicSettingsDto
     */
    'propertyPrice': number;
    /**
     * 
     * @type {number}
     * @memberof PropertyBasicSettingsDto
     */
    'initialPayment': number;
    /**
     * 
     * @type {number}
     * @memberof PropertyBasicSettingsDto
     */
    'rentRevenue': number;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyBasicSettingsDto
     */
    'isRentRevenueGuaranteed': boolean;
    /**
     * 
     * @type {string}
     * @memberof PropertyBasicSettingsDto
     */
    'bankName'?: string;
    /**
     * 
     * @type {number}
     * @memberof PropertyBasicSettingsDto
     */
    'borrowingAmount': number;
    /**
     * 
     * @type {number}
     * @memberof PropertyBasicSettingsDto
     */
    'annualInterestRate': number;
    /**
     * 
     * @type {number}
     * @memberof PropertyBasicSettingsDto
     */
    'borrowingPeriodInYears': number;
    /**
     * 
     * @type {number}
     * @memberof PropertyBasicSettingsDto
     */
    'rentCollectionServiceFee': number;
    /**
     * 
     * @type {number}
     * @memberof PropertyBasicSettingsDto
     */
    'managementCost': number;
    /**
     * 
     * @type {number}
     * @memberof PropertyBasicSettingsDto
     */
    'repairReserve': number;
    /**
     * 
     * @type {Array<OtherItem>}
     * @memberof PropertyBasicSettingsDto
     */
    'otherItems'?: Array<OtherItem>;
}
/**
 * 
 * @export
 * @interface PropertyDocument
 */
export interface PropertyDocument {
    /**
     * 
     * @type {string}
     * @memberof PropertyDocument
     */
    'title': string;
    /**
     * 
     * @type {PropertyAddress}
     * @memberof PropertyDocument
     */
    'address': PropertyAddress;
    /**
     * 
     * @type {string}
     * @memberof PropertyDocument
     */
    'builtAt': string;
    /**
     * 
     * @type {number}
     * @memberof PropertyDocument
     */
    'numberOfSquareMeters': number;
    /**
     * 
     * @type {PropertyOwner}
     * @memberof PropertyDocument
     */
    'owner': PropertyOwner;
    /**
     * 
     * @type {number}
     * @memberof PropertyDocument
     */
    'numberOfRooms'?: number;
    /**
     * 
     * @type {number}
     * @memberof PropertyDocument
     */
    'floorPosition'?: number;
    /**
     * 
     * @type {PropertyRailwayInfo}
     * @memberof PropertyDocument
     */
    'railwayInfo'?: PropertyRailwayInfo;
    /**
     * 
     * @type {string}
     * @memberof PropertyDocument
     */
    'walkingMinutesFromStation'?: PropertyDocumentWalkingMinutesFromStationEnum;
    /**
     * 
     * @type {string}
     * @memberof PropertyDocument
     */
    'cover'?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDocument
     */
    'description'?: string;
    /**
     * 
     * @type {PropertyBasicSettings}
     * @memberof PropertyDocument
     */
    'basicSettings': PropertyBasicSettings;
    /**
     * 
     * @type {PropertyAdvancedSettings}
     * @memberof PropertyDocument
     */
    'advancedSettings'?: PropertyAdvancedSettings;
    /**
     * 
     * @type {PropertyLoanEarlyRepaymentSettings}
     * @memberof PropertyDocument
     */
    'loanEarlyRepaymentSettings'?: PropertyLoanEarlyRepaymentSettings;
    /**
     * 
     * @type {PropertyVolatilitySettings}
     * @memberof PropertyDocument
     */
    'volatilitySettings'?: PropertyVolatilitySettings;
    /**
     * 
     * @type {string}
     * @memberof PropertyDocument
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDocument
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDocument
     */
    'updatedAt'?: string;
}

export const PropertyDocumentWalkingMinutesFromStationEnum = {
    OneToFourMinutes: 'one-to-four-minutes',
    FiveToTenMinutes: 'five-to-ten-minutes',
    ElevenToTwelveMinutes: 'eleven-to-twelve-minutes',
    MoreThanOrEqualToThirteenMinutes: 'more-than-or-equal-to-thirteen-minutes'
} as const;

export type PropertyDocumentWalkingMinutesFromStationEnum = typeof PropertyDocumentWalkingMinutesFromStationEnum[keyof typeof PropertyDocumentWalkingMinutesFromStationEnum];

/**
 * 
 * @export
 * @interface PropertyLoanEarlyRepaymentSettings
 */
export interface PropertyLoanEarlyRepaymentSettings {
    /**
     * 
     * @type {string}
     * @memberof PropertyLoanEarlyRepaymentSettings
     */
    'loanEarlyRepaymentType': PropertyLoanEarlyRepaymentSettingsLoanEarlyRepaymentTypeEnum;
    /**
     * 
     * @type {Array<LoanEarlyRepayment>}
     * @memberof PropertyLoanEarlyRepaymentSettings
     */
    'loanEarlyRepayments': Array<LoanEarlyRepayment>;
}

export const PropertyLoanEarlyRepaymentSettingsLoanEarlyRepaymentTypeEnum = {
    AmountReduction: 'amount-reduction',
    PeriodShortening: 'period-shortening'
} as const;

export type PropertyLoanEarlyRepaymentSettingsLoanEarlyRepaymentTypeEnum = typeof PropertyLoanEarlyRepaymentSettingsLoanEarlyRepaymentTypeEnum[keyof typeof PropertyLoanEarlyRepaymentSettingsLoanEarlyRepaymentTypeEnum];

/**
 * 
 * @export
 * @interface PropertyOwner
 */
export interface PropertyOwner {
    /**
     * 
     * @type {string}
     * @memberof PropertyOwner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PropertyOwner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyOwner
     */
    'avatarUrl'?: string;
}
/**
 * 
 * @export
 * @interface PropertyOwnerDto
 */
export interface PropertyOwnerDto {
    /**
     * 
     * @type {string}
     * @memberof PropertyOwnerDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PropertyOwnerDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyOwnerDto
     */
    'avatarUrl'?: string;
}
/**
 * 
 * @export
 * @interface PropertyRailwayInfo
 */
export interface PropertyRailwayInfo {
    /**
     * 
     * @type {PropertyRailwayInfoItem}
     * @memberof PropertyRailwayInfo
     */
    'company'?: PropertyRailwayInfoItem;
    /**
     * 
     * @type {PropertyRailwayInfoItem}
     * @memberof PropertyRailwayInfo
     */
    'line'?: PropertyRailwayInfoItem;
    /**
     * 
     * @type {PropertyRailwayInfoItem}
     * @memberof PropertyRailwayInfo
     */
    'station'?: PropertyRailwayInfoItem;
}
/**
 * 
 * @export
 * @interface PropertyRailwayInfoDto
 */
export interface PropertyRailwayInfoDto {
    /**
     * 
     * @type {RailwayInfo}
     * @memberof PropertyRailwayInfoDto
     */
    'company'?: RailwayInfo;
    /**
     * 
     * @type {RailwayInfo}
     * @memberof PropertyRailwayInfoDto
     */
    'line'?: RailwayInfo;
    /**
     * 
     * @type {RailwayInfo}
     * @memberof PropertyRailwayInfoDto
     */
    'station'?: RailwayInfo;
}
/**
 * 
 * @export
 * @interface PropertyRailwayInfoItem
 */
export interface PropertyRailwayInfoItem {
    /**
     * 
     * @type {string}
     * @memberof PropertyRailwayInfoItem
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof PropertyRailwayInfoItem
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface PropertyVolatilitySettings
 */
export interface PropertyVolatilitySettings {
    /**
     * 
     * @type {number}
     * @memberof PropertyVolatilitySettings
     */
    'rentChangeRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof PropertyVolatilitySettings
     */
    'sellingPriceChangeRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof PropertyVolatilitySettings
     */
    'propertyPriceDropRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof PropertyVolatilitySettings
     */
    'vacancyRate'?: number;
}
/**
 * 
 * @export
 * @interface PropertyVolatilitySettingsDto
 */
export interface PropertyVolatilitySettingsDto {
    /**
     * 
     * @type {number}
     * @memberof PropertyVolatilitySettingsDto
     */
    'rentChangeRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof PropertyVolatilitySettingsDto
     */
    'sellingPriceChangeRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof PropertyVolatilitySettingsDto
     */
    'propertyPriceDropRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof PropertyVolatilitySettingsDto
     */
    'vacancyRate'?: number;
}
/**
 * 
 * @export
 * @interface RailwayCompanyDocument
 */
export interface RailwayCompanyDocument {
    /**
     * 
     * @type {string}
     * @memberof RailwayCompanyDocument
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RailwayCompanyDocument
     */
    'lines'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RailwayCompanyDocument
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RailwayCompanyDocument
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof RailwayCompanyDocument
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface RailwayInfo
 */
export interface RailwayInfo {
    /**
     * 
     * @type {string}
     * @memberof RailwayInfo
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof RailwayInfo
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface RailwayLineDocument
 */
export interface RailwayLineDocument {
    /**
     * 
     * @type {string}
     * @memberof RailwayLineDocument
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RailwayLineDocument
     */
    'company_cd': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RailwayLineDocument
     */
    'stations'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RailwayLineDocument
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RailwayLineDocument
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof RailwayLineDocument
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface RailwayStationDocument
 */
export interface RailwayStationDocument {
    /**
     * 
     * @type {string}
     * @memberof RailwayStationDocument
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RailwayStationDocument
     */
    'line_cd': string;
    /**
     * 
     * @type {string}
     * @memberof RailwayStationDocument
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RailwayStationDocument
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof RailwayStationDocument
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface RegisterUserDto
 */
export interface RegisterUserDto {
    /**
     * 
     * @type {string}
     * @memberof RegisterUserDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserDto
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface TotalWithItems
 */
export interface TotalWithItems {
    /**
     * 
     * @type {Array<number>}
     * @memberof TotalWithItems
     */
    'total': Array<number>;
    /**
     * 
     * @type {object}
     * @memberof TotalWithItems
     */
    'items': object;
}
/**
 * 
 * @export
 * @interface UpdateMyPropertyDto
 */
export interface UpdateMyPropertyDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateMyPropertyDto
     */
    'title'?: string;
    /**
     * 
     * @type {PropertyAddressDto}
     * @memberof UpdateMyPropertyDto
     */
    'address'?: PropertyAddressDto;
    /**
     * 
     * @type {string}
     * @memberof UpdateMyPropertyDto
     */
    'builtAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateMyPropertyDto
     */
    'numberOfSquareMeters'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateMyPropertyDto
     */
    'numberOfRooms'?: number;
    /**
     * 
     * @type {PropertyOwnerDto}
     * @memberof UpdateMyPropertyDto
     */
    'owner'?: PropertyOwnerDto;
    /**
     * 
     * @type {string}
     * @memberof UpdateMyPropertyDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMyPropertyDto
     */
    'cover'?: string;
    /**
     * 
     * @type {PropertyBasicSettingsDto}
     * @memberof UpdateMyPropertyDto
     */
    'basicSettings'?: PropertyBasicSettingsDto;
    /**
     * 
     * @type {PropertyAdvancedSettingsDto}
     * @memberof UpdateMyPropertyDto
     */
    'advancedSettings'?: PropertyAdvancedSettingsDto;
    /**
     * 
     * @type {LoanEarlyRepaymentSettingsDto}
     * @memberof UpdateMyPropertyDto
     */
    'loanEarlyRepaymentSettings'?: LoanEarlyRepaymentSettingsDto;
    /**
     * 
     * @type {PropertyVolatilitySettingsDto}
     * @memberof UpdateMyPropertyDto
     */
    'volatilitySettings'?: PropertyVolatilitySettingsDto;
}
/**
 * 
 * @export
 * @interface UpdateUserDto
 */
export interface UpdateUserDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'displayName': string;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'uid': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'email': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserDto
     */
    'roles': Array<UserDtoRolesEnum>;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'lastLoginAt': string;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    'propertiesCount': number;
}

export const UserDtoRolesEnum = {
    User: 'user',
    Admin: 'admin'
} as const;

export type UserDtoRolesEnum = typeof UserDtoRolesEnum[keyof typeof UserDtoRolesEnum];

/**
 * 
 * @export
 * @interface UsersDto
 */
export interface UsersDto {
    /**
     * 
     * @type {number}
     * @memberof UsersDto
     */
    'total': number;
    /**
     * 
     * @type {Array<UserDto>}
     * @memberof UsersDto
     */
    'items': Array<UserDto>;
}
/**
 * 
 * @export
 * @interface VerifyCodeDto
 */
export interface VerifyCodeDto {
    /**
     * 
     * @type {string}
     * @memberof VerifyCodeDto
     */
    'verificationCode': string;
}

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {'createdAt' | 'updatedAt' | 'address.principal' | 'address.city'} [orderBy] 
         * @param {string} [filterBy] 
         * @param {string} [userId] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {'asc' | 'desc'} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPropertiesControllerFindAll: async (orderBy?: 'createdAt' | 'updatedAt' | 'address.principal' | 'address.city', filterBy?: string, userId?: string, page?: number, limit?: number, direction?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/properties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (filterBy !== undefined) {
                localVarQueryParameter['filterBy'] = filterBy;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPropertiesControllerFindOne: async (propertyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('adminPropertiesControllerFindOne', 'propertyId', propertyId)
            const localVarPath = `/api/v1/admin/properties/{propertyId}`
                .replace(`{${"propertyId"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPropertiesControllerGetSellingEstimation: async (propertyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('adminPropertiesControllerGetSellingEstimation', 'propertyId', propertyId)
            const localVarPath = `/api/v1/admin/properties/{propertyId}/selling-estimation`
                .replace(`{${"propertyId"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPropertiesControllerRemove: async (propertyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('adminPropertiesControllerRemove', 'propertyId', propertyId)
            const localVarPath = `/api/v1/admin/properties/{propertyId}`
                .replace(`{${"propertyId"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} propertyId 
         * @param {UpdateMyPropertyDto} updateMyPropertyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPropertiesControllerUpdate: async (propertyId: string, updateMyPropertyDto: UpdateMyPropertyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('adminPropertiesControllerUpdate', 'propertyId', propertyId)
            // verify required parameter 'updateMyPropertyDto' is not null or undefined
            assertParamExists('adminPropertiesControllerUpdate', 'updateMyPropertyDto', updateMyPropertyDto)
            const localVarPath = `/api/v1/admin/properties/{propertyId}`
                .replace(`{${"propertyId"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMyPropertyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'email' | 'displayName' | 'firstName' | 'lastName' | 'createdAt'} [orderBy] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {'asc' | 'desc'} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersControllerFindAll: async (orderBy?: 'email' | 'displayName' | 'firstName' | 'lastName' | 'createdAt', page?: number, limit?: number, direction?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminUsersControllerFindOne', 'id', id)
            const localVarPath = `/api/v1/admin/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {'createdAt' | 'updatedAt' | 'address.principal' | 'address.city'} [orderBy] 
         * @param {string} [filterBy] 
         * @param {string} [userId] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {'asc' | 'desc'} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPropertiesControllerFindAll(orderBy?: 'createdAt' | 'updatedAt' | 'address.principal' | 'address.city', filterBy?: string, userId?: string, page?: number, limit?: number, direction?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMyPropertiesResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPropertiesControllerFindAll(orderBy, filterBy, userId, page, limit, direction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPropertiesControllerFindOne(propertyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPropertiesControllerFindOne(propertyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPropertiesControllerGetSellingEstimation(propertyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSellingEstimationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPropertiesControllerGetSellingEstimation(propertyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPropertiesControllerRemove(propertyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPropertiesControllerRemove(propertyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} propertyId 
         * @param {UpdateMyPropertyDto} updateMyPropertyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPropertiesControllerUpdate(propertyId: string, updateMyPropertyDto: UpdateMyPropertyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPropertiesControllerUpdate(propertyId, updateMyPropertyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'email' | 'displayName' | 'firstName' | 'lastName' | 'createdAt'} [orderBy] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {'asc' | 'desc'} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUsersControllerFindAll(orderBy?: 'email' | 'displayName' | 'firstName' | 'lastName' | 'createdAt', page?: number, limit?: number, direction?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUsersControllerFindAll(orderBy, page, limit, direction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUsersControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUsersControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * 
         * @param {'createdAt' | 'updatedAt' | 'address.principal' | 'address.city'} [orderBy] 
         * @param {string} [filterBy] 
         * @param {string} [userId] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {'asc' | 'desc'} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPropertiesControllerFindAll(orderBy?: 'createdAt' | 'updatedAt' | 'address.principal' | 'address.city', filterBy?: string, userId?: string, page?: number, limit?: number, direction?: 'asc' | 'desc', options?: any): AxiosPromise<GetMyPropertiesResponseDto> {
            return localVarFp.adminPropertiesControllerFindAll(orderBy, filterBy, userId, page, limit, direction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPropertiesControllerFindOne(propertyId: string, options?: any): AxiosPromise<PropertyDocument> {
            return localVarFp.adminPropertiesControllerFindOne(propertyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPropertiesControllerGetSellingEstimation(propertyId: string, options?: any): AxiosPromise<GetSellingEstimationResponseDto> {
            return localVarFp.adminPropertiesControllerGetSellingEstimation(propertyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPropertiesControllerRemove(propertyId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminPropertiesControllerRemove(propertyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} propertyId 
         * @param {UpdateMyPropertyDto} updateMyPropertyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPropertiesControllerUpdate(propertyId: string, updateMyPropertyDto: UpdateMyPropertyDto, options?: any): AxiosPromise<PropertyDocument> {
            return localVarFp.adminPropertiesControllerUpdate(propertyId, updateMyPropertyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'email' | 'displayName' | 'firstName' | 'lastName' | 'createdAt'} [orderBy] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {'asc' | 'desc'} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersControllerFindAll(orderBy?: 'email' | 'displayName' | 'firstName' | 'lastName' | 'createdAt', page?: number, limit?: number, direction?: 'asc' | 'desc', options?: any): AxiosPromise<UsersDto> {
            return localVarFp.adminUsersControllerFindAll(orderBy, page, limit, direction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUsersControllerFindOne(id: string, options?: any): AxiosPromise<UserDto> {
            return localVarFp.adminUsersControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminPropertiesControllerFindAll operation in AdminApi.
 * @export
 * @interface AdminApiAdminPropertiesControllerFindAllRequest
 */
export interface AdminApiAdminPropertiesControllerFindAllRequest {
    /**
     * 
     * @type {'createdAt' | 'updatedAt' | 'address.principal' | 'address.city'}
     * @memberof AdminApiAdminPropertiesControllerFindAll
     */
    readonly orderBy?: 'createdAt' | 'updatedAt' | 'address.principal' | 'address.city'

    /**
     * 
     * @type {string}
     * @memberof AdminApiAdminPropertiesControllerFindAll
     */
    readonly filterBy?: string

    /**
     * 
     * @type {string}
     * @memberof AdminApiAdminPropertiesControllerFindAll
     */
    readonly userId?: string

    /**
     * 
     * @type {number}
     * @memberof AdminApiAdminPropertiesControllerFindAll
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminApiAdminPropertiesControllerFindAll
     */
    readonly limit?: number

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof AdminApiAdminPropertiesControllerFindAll
     */
    readonly direction?: 'asc' | 'desc'
}

/**
 * Request parameters for adminPropertiesControllerFindOne operation in AdminApi.
 * @export
 * @interface AdminApiAdminPropertiesControllerFindOneRequest
 */
export interface AdminApiAdminPropertiesControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminApiAdminPropertiesControllerFindOne
     */
    readonly propertyId: string
}

/**
 * Request parameters for adminPropertiesControllerGetSellingEstimation operation in AdminApi.
 * @export
 * @interface AdminApiAdminPropertiesControllerGetSellingEstimationRequest
 */
export interface AdminApiAdminPropertiesControllerGetSellingEstimationRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminApiAdminPropertiesControllerGetSellingEstimation
     */
    readonly propertyId: string
}

/**
 * Request parameters for adminPropertiesControllerRemove operation in AdminApi.
 * @export
 * @interface AdminApiAdminPropertiesControllerRemoveRequest
 */
export interface AdminApiAdminPropertiesControllerRemoveRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminApiAdminPropertiesControllerRemove
     */
    readonly propertyId: string
}

/**
 * Request parameters for adminPropertiesControllerUpdate operation in AdminApi.
 * @export
 * @interface AdminApiAdminPropertiesControllerUpdateRequest
 */
export interface AdminApiAdminPropertiesControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminApiAdminPropertiesControllerUpdate
     */
    readonly propertyId: string

    /**
     * 
     * @type {UpdateMyPropertyDto}
     * @memberof AdminApiAdminPropertiesControllerUpdate
     */
    readonly updateMyPropertyDto: UpdateMyPropertyDto
}

/**
 * Request parameters for adminUsersControllerFindAll operation in AdminApi.
 * @export
 * @interface AdminApiAdminUsersControllerFindAllRequest
 */
export interface AdminApiAdminUsersControllerFindAllRequest {
    /**
     * 
     * @type {'email' | 'displayName' | 'firstName' | 'lastName' | 'createdAt'}
     * @memberof AdminApiAdminUsersControllerFindAll
     */
    readonly orderBy?: 'email' | 'displayName' | 'firstName' | 'lastName' | 'createdAt'

    /**
     * 
     * @type {number}
     * @memberof AdminApiAdminUsersControllerFindAll
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminApiAdminUsersControllerFindAll
     */
    readonly limit?: number

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof AdminApiAdminUsersControllerFindAll
     */
    readonly direction?: 'asc' | 'desc'
}

/**
 * Request parameters for adminUsersControllerFindOne operation in AdminApi.
 * @export
 * @interface AdminApiAdminUsersControllerFindOneRequest
 */
export interface AdminApiAdminUsersControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminApiAdminUsersControllerFindOne
     */
    readonly id: string
}

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * 
     * @param {AdminApiAdminPropertiesControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminPropertiesControllerFindAll(requestParameters: AdminApiAdminPropertiesControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminPropertiesControllerFindAll(requestParameters.orderBy, requestParameters.filterBy, requestParameters.userId, requestParameters.page, requestParameters.limit, requestParameters.direction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminApiAdminPropertiesControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminPropertiesControllerFindOne(requestParameters: AdminApiAdminPropertiesControllerFindOneRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminPropertiesControllerFindOne(requestParameters.propertyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminApiAdminPropertiesControllerGetSellingEstimationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminPropertiesControllerGetSellingEstimation(requestParameters: AdminApiAdminPropertiesControllerGetSellingEstimationRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminPropertiesControllerGetSellingEstimation(requestParameters.propertyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminApiAdminPropertiesControllerRemoveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminPropertiesControllerRemove(requestParameters: AdminApiAdminPropertiesControllerRemoveRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminPropertiesControllerRemove(requestParameters.propertyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminApiAdminPropertiesControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminPropertiesControllerUpdate(requestParameters: AdminApiAdminPropertiesControllerUpdateRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminPropertiesControllerUpdate(requestParameters.propertyId, requestParameters.updateMyPropertyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminApiAdminUsersControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUsersControllerFindAll(requestParameters: AdminApiAdminUsersControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminUsersControllerFindAll(requestParameters.orderBy, requestParameters.page, requestParameters.limit, requestParameters.direction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminApiAdminUsersControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUsersControllerFindOne(requestParameters: AdminApiAdminUsersControllerFindOneRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminUsersControllerFindOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} zipCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressInfoControllerGetAddressInfo: async (zipCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zipCode' is not null or undefined
            assertParamExists('addressInfoControllerGetAddressInfo', 'zipCode', zipCode)
            const localVarPath = `/api/v1/address-info/{zipCode}`
                .replace(`{${"zipCode"}}`, encodeURIComponent(String(zipCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHello: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateMyPropertyDto} createMyPropertyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myPropertiesControllerCreate: async (createMyPropertyDto: CreateMyPropertyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMyPropertyDto' is not null or undefined
            assertParamExists('myPropertiesControllerCreate', 'createMyPropertyDto', createMyPropertyDto)
            const localVarPath = `/api/v1/my-properties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMyPropertyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'createdAt' | 'updatedAt' | 'address.principal' | 'address.city'} [orderBy] 
         * @param {string} [filterBy] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {'asc' | 'desc'} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myPropertiesControllerFindAll: async (orderBy?: 'createdAt' | 'updatedAt' | 'address.principal' | 'address.city', filterBy?: string, page?: number, limit?: number, direction?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/my-properties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (filterBy !== undefined) {
                localVarQueryParameter['filterBy'] = filterBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myPropertiesControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('myPropertiesControllerFindOne', 'id', id)
            const localVarPath = `/api/v1/my-properties/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myPropertiesControllerGetSellingEstimation: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('myPropertiesControllerGetSellingEstimation', 'id', id)
            const localVarPath = `/api/v1/my-properties/{id}/selling-estimation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myPropertiesControllerInquireSellingEstimation: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('myPropertiesControllerInquireSellingEstimation', 'id', id)
            const localVarPath = `/api/v1/my-properties/{id}/selling-estimation/inquire`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myPropertiesControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('myPropertiesControllerRemove', 'id', id)
            const localVarPath = `/api/v1/my-properties/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myPropertiesControllerRequestEvaluationReport: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('myPropertiesControllerRequestEvaluationReport', 'id', id)
            const localVarPath = `/api/v1/my-properties/{id}/request-evaluation-report`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateMyPropertyDto} updateMyPropertyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myPropertiesControllerUpdate: async (id: string, updateMyPropertyDto: UpdateMyPropertyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('myPropertiesControllerUpdate', 'id', id)
            // verify required parameter 'updateMyPropertyDto' is not null or undefined
            assertParamExists('myPropertiesControllerUpdate', 'updateMyPropertyDto', updateMyPropertyDto)
            const localVarPath = `/api/v1/my-properties/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMyPropertyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'createdAt' | 'updatedAt' | 'address.principal' | 'address.city'} [orderBy] 
         * @param {string} [filterBy] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {'asc' | 'desc'} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertiesControllerFindAll: async (orderBy?: 'createdAt' | 'updatedAt' | 'address.principal' | 'address.city', filterBy?: string, page?: number, limit?: number, direction?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/properties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (filterBy !== undefined) {
                localVarQueryParameter['filterBy'] = filterBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertiesControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('propertiesControllerFindOne', 'id', id)
            const localVarPath = `/api/v1/properties/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertiesControllerGetLoanPayments: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('propertiesControllerGetLoanPayments', 'id', id)
            const localVarPath = `/api/v1/properties/{id}/loan-payments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertiesControllerSimulate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('propertiesControllerSimulate', 'id', id)
            const localVarPath = `/api/v1/properties/{id}/simulate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        railwayCompaniesControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/railway-companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        railwayCompaniesControllerFindOne: async (companyCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyCode' is not null or undefined
            assertParamExists('railwayCompaniesControllerFindOne', 'companyCode', companyCode)
            const localVarPath = `/api/v1/railway-companies/{companyCode}`
                .replace(`{${"companyCode"}}`, encodeURIComponent(String(companyCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        railwayLinesControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/railway-lines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} lineCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        railwayLinesControllerFindOne: async (lineCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lineCode' is not null or undefined
            assertParamExists('railwayLinesControllerFindOne', 'lineCode', lineCode)
            const localVarPath = `/api/v1/railway-lines/{lineCode}`
                .replace(`{${"lineCode"}}`, encodeURIComponent(String(lineCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} lineCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        railwayStationsControllerFindAll: async (lineCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lineCode' is not null or undefined
            assertParamExists('railwayStationsControllerFindAll', 'lineCode', lineCode)
            const localVarPath = `/api/v1/railway-lines/{lineCode}/railway-stations`
                .replace(`{${"lineCode"}}`, encodeURIComponent(String(lineCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} lineCode 
         * @param {string} stationCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        railwayStationsControllerFindOne: async (lineCode: string, stationCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lineCode' is not null or undefined
            assertParamExists('railwayStationsControllerFindOne', 'lineCode', lineCode)
            // verify required parameter 'stationCode' is not null or undefined
            assertParamExists('railwayStationsControllerFindOne', 'stationCode', stationCode)
            const localVarPath = `/api/v1/railway-lines/{lineCode}/railway-stations/{stationCode}`
                .replace(`{${"lineCode"}}`, encodeURIComponent(String(lineCode)))
                .replace(`{${"stationCode"}}`, encodeURIComponent(String(stationCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetLinkedAccounts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/linked-accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerLineAccountLink: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/line-account-link`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LineCallbackBodyDto} lineCallbackBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerLineAccountLinkCallback: async (lineCallbackBodyDto: LineCallbackBodyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lineCallbackBodyDto' is not null or undefined
            assertParamExists('usersControllerLineAccountLinkCallback', 'lineCallbackBodyDto', lineCallbackBodyDto)
            const localVarPath = `/api/v1/users/line-account-link-callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lineCallbackBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerLineAccountUnlink: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/line-account-unlink`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LineCallbackBodyDto} lineCallbackBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerLineCallback: async (lineCallbackBodyDto: LineCallbackBodyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lineCallbackBodyDto' is not null or undefined
            assertParamExists('usersControllerLineCallback', 'lineCallbackBodyDto', lineCallbackBodyDto)
            const localVarPath = `/api/v1/users/line-callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lineCallbackBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerLineLogin: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/line-login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterUserDto} registerUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerRegister: async (registerUserDto: RegisterUserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerUserDto' is not null or undefined
            assertParamExists('usersControllerRegister', 'registerUserDto', registerUserDto)
            const localVarPath = `/api/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerResendVerificationCode: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/resend-verification-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateUserDto} updateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateUser: async (updateUserDto: UpdateUserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateUserDto' is not null or undefined
            assertParamExists('usersControllerUpdateUser', 'updateUserDto', updateUserDto)
            const localVarPath = `/api/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyCodeDto} verifyCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerVerifyCode: async (verifyCodeDto: VerifyCodeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifyCodeDto' is not null or undefined
            assertParamExists('usersControllerVerifyCode', 'verifyCodeDto', verifyCodeDto)
            const localVarPath = `/api/v1/users/verify-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyCodeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} startDate 
         * @param {number} numberOfYears 
         * @param {'by-month' | 'by-year'} cycle 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xaxisLabelsControllerGetXAxisLabels: async (startDate: string, numberOfYears: number, cycle: 'by-month' | 'by-year', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('xaxisLabelsControllerGetXAxisLabels', 'startDate', startDate)
            // verify required parameter 'numberOfYears' is not null or undefined
            assertParamExists('xaxisLabelsControllerGetXAxisLabels', 'numberOfYears', numberOfYears)
            // verify required parameter 'cycle' is not null or undefined
            assertParamExists('xaxisLabelsControllerGetXAxisLabels', 'cycle', cycle)
            const localVarPath = `/api/v1/xaxis-labels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (numberOfYears !== undefined) {
                localVarQueryParameter['numberOfYears'] = numberOfYears;
            }

            if (cycle !== undefined) {
                localVarQueryParameter['cycle'] = cycle;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} zipCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressInfoControllerGetAddressInfo(zipCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAddressInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressInfoControllerGetAddressInfo(zipCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appControllerGetHello(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appControllerGetHello(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateMyPropertyDto} createMyPropertyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async myPropertiesControllerCreate(createMyPropertyDto: CreateMyPropertyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.myPropertiesControllerCreate(createMyPropertyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'createdAt' | 'updatedAt' | 'address.principal' | 'address.city'} [orderBy] 
         * @param {string} [filterBy] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {'asc' | 'desc'} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async myPropertiesControllerFindAll(orderBy?: 'createdAt' | 'updatedAt' | 'address.principal' | 'address.city', filterBy?: string, page?: number, limit?: number, direction?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMyPropertiesResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.myPropertiesControllerFindAll(orderBy, filterBy, page, limit, direction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async myPropertiesControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.myPropertiesControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async myPropertiesControllerGetSellingEstimation(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSellingEstimationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.myPropertiesControllerGetSellingEstimation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async myPropertiesControllerInquireSellingEstimation(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.myPropertiesControllerInquireSellingEstimation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async myPropertiesControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.myPropertiesControllerRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async myPropertiesControllerRequestEvaluationReport(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.myPropertiesControllerRequestEvaluationReport(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateMyPropertyDto} updateMyPropertyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async myPropertiesControllerUpdate(id: string, updateMyPropertyDto: UpdateMyPropertyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.myPropertiesControllerUpdate(id, updateMyPropertyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'createdAt' | 'updatedAt' | 'address.principal' | 'address.city'} [orderBy] 
         * @param {string} [filterBy] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {'asc' | 'desc'} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertiesControllerFindAll(orderBy?: 'createdAt' | 'updatedAt' | 'address.principal' | 'address.city', filterBy?: string, page?: number, limit?: number, direction?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPropertiesResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propertiesControllerFindAll(orderBy, filterBy, page, limit, direction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertiesControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propertiesControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertiesControllerGetLoanPayments(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLoanPaymentsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propertiesControllerGetLoanPayments(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertiesControllerSimulate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPropertySimulateResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propertiesControllerSimulate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async railwayCompaniesControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRailwayCompaniesResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.railwayCompaniesControllerFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async railwayCompaniesControllerFindOne(companyCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RailwayCompanyDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.railwayCompaniesControllerFindOne(companyCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async railwayLinesControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRailwayLinesResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.railwayLinesControllerFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} lineCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async railwayLinesControllerFindOne(lineCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RailwayLineDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.railwayLinesControllerFindOne(lineCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} lineCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async railwayStationsControllerFindAll(lineCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRailwayStationsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.railwayStationsControllerFindAll(lineCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} lineCode 
         * @param {string} stationCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async railwayStationsControllerFindOne(lineCode: string, stationCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RailwayStationDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.railwayStationsControllerFindOne(lineCode, stationCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerGetLinkedAccounts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerGetLinkedAccounts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerLineAccountLink(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerLineAccountLink(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LineCallbackBodyDto} lineCallbackBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerLineAccountLinkCallback(lineCallbackBodyDto: LineCallbackBodyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerLineAccountLinkCallback(lineCallbackBodyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerLineAccountUnlink(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerLineAccountUnlink(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LineCallbackBodyDto} lineCallbackBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerLineCallback(lineCallbackBodyDto: LineCallbackBodyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerLineCallback(lineCallbackBodyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerLineLogin(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerLineLogin(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RegisterUserDto} registerUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerRegister(registerUserDto: RegisterUserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerRegister(registerUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerResendVerificationCode(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerResendVerificationCode(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateUserDto} updateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerUpdateUser(updateUserDto: UpdateUserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerUpdateUser(updateUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VerifyCodeDto} verifyCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerVerifyCode(verifyCodeDto: VerifyCodeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerVerifyCode(verifyCodeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} startDate 
         * @param {number} numberOfYears 
         * @param {'by-month' | 'by-year'} cycle 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xaxisLabelsControllerGetXAxisLabels(startDate: string, numberOfYears: number, cycle: 'by-month' | 'by-year', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xaxisLabelsControllerGetXAxisLabels(startDate, numberOfYears, cycle, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {string} zipCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressInfoControllerGetAddressInfo(zipCode: string, options?: any): AxiosPromise<GetAddressInfoDto> {
            return localVarFp.addressInfoControllerGetAddressInfo(zipCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHello(options?: any): AxiosPromise<string> {
            return localVarFp.appControllerGetHello(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateMyPropertyDto} createMyPropertyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myPropertiesControllerCreate(createMyPropertyDto: CreateMyPropertyDto, options?: any): AxiosPromise<PropertyDocument> {
            return localVarFp.myPropertiesControllerCreate(createMyPropertyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'createdAt' | 'updatedAt' | 'address.principal' | 'address.city'} [orderBy] 
         * @param {string} [filterBy] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {'asc' | 'desc'} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myPropertiesControllerFindAll(orderBy?: 'createdAt' | 'updatedAt' | 'address.principal' | 'address.city', filterBy?: string, page?: number, limit?: number, direction?: 'asc' | 'desc', options?: any): AxiosPromise<GetMyPropertiesResponseDto> {
            return localVarFp.myPropertiesControllerFindAll(orderBy, filterBy, page, limit, direction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myPropertiesControllerFindOne(id: string, options?: any): AxiosPromise<PropertyDocument> {
            return localVarFp.myPropertiesControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myPropertiesControllerGetSellingEstimation(id: string, options?: any): AxiosPromise<GetSellingEstimationResponseDto> {
            return localVarFp.myPropertiesControllerGetSellingEstimation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myPropertiesControllerInquireSellingEstimation(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.myPropertiesControllerInquireSellingEstimation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myPropertiesControllerRemove(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.myPropertiesControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myPropertiesControllerRequestEvaluationReport(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.myPropertiesControllerRequestEvaluationReport(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateMyPropertyDto} updateMyPropertyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myPropertiesControllerUpdate(id: string, updateMyPropertyDto: UpdateMyPropertyDto, options?: any): AxiosPromise<PropertyDocument> {
            return localVarFp.myPropertiesControllerUpdate(id, updateMyPropertyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'createdAt' | 'updatedAt' | 'address.principal' | 'address.city'} [orderBy] 
         * @param {string} [filterBy] 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {'asc' | 'desc'} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertiesControllerFindAll(orderBy?: 'createdAt' | 'updatedAt' | 'address.principal' | 'address.city', filterBy?: string, page?: number, limit?: number, direction?: 'asc' | 'desc', options?: any): AxiosPromise<GetPropertiesResponseDto> {
            return localVarFp.propertiesControllerFindAll(orderBy, filterBy, page, limit, direction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertiesControllerFindOne(id: string, options?: any): AxiosPromise<PropertyDocument> {
            return localVarFp.propertiesControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertiesControllerGetLoanPayments(id: string, options?: any): AxiosPromise<GetLoanPaymentsDto> {
            return localVarFp.propertiesControllerGetLoanPayments(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertiesControllerSimulate(id: string, options?: any): AxiosPromise<GetPropertySimulateResponseDto> {
            return localVarFp.propertiesControllerSimulate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        railwayCompaniesControllerFindAll(options?: any): AxiosPromise<GetRailwayCompaniesResponseDto> {
            return localVarFp.railwayCompaniesControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        railwayCompaniesControllerFindOne(companyCode: string, options?: any): AxiosPromise<RailwayCompanyDocument> {
            return localVarFp.railwayCompaniesControllerFindOne(companyCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        railwayLinesControllerFindAll(options?: any): AxiosPromise<GetRailwayLinesResponseDto> {
            return localVarFp.railwayLinesControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} lineCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        railwayLinesControllerFindOne(lineCode: string, options?: any): AxiosPromise<RailwayLineDocument> {
            return localVarFp.railwayLinesControllerFindOne(lineCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} lineCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        railwayStationsControllerFindAll(lineCode: string, options?: any): AxiosPromise<GetRailwayStationsResponseDto> {
            return localVarFp.railwayStationsControllerFindAll(lineCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} lineCode 
         * @param {string} stationCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        railwayStationsControllerFindOne(lineCode: string, stationCode: string, options?: any): AxiosPromise<RailwayStationDocument> {
            return localVarFp.railwayStationsControllerFindOne(lineCode, stationCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetLinkedAccounts(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.usersControllerGetLinkedAccounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerLineAccountLink(options?: any): AxiosPromise<string> {
            return localVarFp.usersControllerLineAccountLink(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LineCallbackBodyDto} lineCallbackBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerLineAccountLinkCallback(lineCallbackBodyDto: LineCallbackBodyDto, options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerLineAccountLinkCallback(lineCallbackBodyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerLineAccountUnlink(options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerLineAccountUnlink(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LineCallbackBodyDto} lineCallbackBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerLineCallback(lineCallbackBodyDto: LineCallbackBodyDto, options?: any): AxiosPromise<string> {
            return localVarFp.usersControllerLineCallback(lineCallbackBodyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerLineLogin(options?: any): AxiosPromise<string> {
            return localVarFp.usersControllerLineLogin(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterUserDto} registerUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerRegister(registerUserDto: RegisterUserDto, options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerRegister(registerUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerResendVerificationCode(options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerResendVerificationCode(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateUserDto} updateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateUser(updateUserDto: UpdateUserDto, options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerUpdateUser(updateUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifyCodeDto} verifyCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerVerifyCode(verifyCodeDto: VerifyCodeDto, options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerVerifyCode(verifyCodeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} startDate 
         * @param {number} numberOfYears 
         * @param {'by-month' | 'by-year'} cycle 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xaxisLabelsControllerGetXAxisLabels(startDate: string, numberOfYears: number, cycle: 'by-month' | 'by-year', options?: any): AxiosPromise<Array<string>> {
            return localVarFp.xaxisLabelsControllerGetXAxisLabels(startDate, numberOfYears, cycle, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addressInfoControllerGetAddressInfo operation in DefaultApi.
 * @export
 * @interface DefaultApiAddressInfoControllerGetAddressInfoRequest
 */
export interface DefaultApiAddressInfoControllerGetAddressInfoRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiAddressInfoControllerGetAddressInfo
     */
    readonly zipCode: string
}

/**
 * Request parameters for myPropertiesControllerCreate operation in DefaultApi.
 * @export
 * @interface DefaultApiMyPropertiesControllerCreateRequest
 */
export interface DefaultApiMyPropertiesControllerCreateRequest {
    /**
     * 
     * @type {CreateMyPropertyDto}
     * @memberof DefaultApiMyPropertiesControllerCreate
     */
    readonly createMyPropertyDto: CreateMyPropertyDto
}

/**
 * Request parameters for myPropertiesControllerFindAll operation in DefaultApi.
 * @export
 * @interface DefaultApiMyPropertiesControllerFindAllRequest
 */
export interface DefaultApiMyPropertiesControllerFindAllRequest {
    /**
     * 
     * @type {'createdAt' | 'updatedAt' | 'address.principal' | 'address.city'}
     * @memberof DefaultApiMyPropertiesControllerFindAll
     */
    readonly orderBy?: 'createdAt' | 'updatedAt' | 'address.principal' | 'address.city'

    /**
     * 
     * @type {string}
     * @memberof DefaultApiMyPropertiesControllerFindAll
     */
    readonly filterBy?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiMyPropertiesControllerFindAll
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiMyPropertiesControllerFindAll
     */
    readonly limit?: number

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof DefaultApiMyPropertiesControllerFindAll
     */
    readonly direction?: 'asc' | 'desc'
}

/**
 * Request parameters for myPropertiesControllerFindOne operation in DefaultApi.
 * @export
 * @interface DefaultApiMyPropertiesControllerFindOneRequest
 */
export interface DefaultApiMyPropertiesControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiMyPropertiesControllerFindOne
     */
    readonly id: string
}

/**
 * Request parameters for myPropertiesControllerGetSellingEstimation operation in DefaultApi.
 * @export
 * @interface DefaultApiMyPropertiesControllerGetSellingEstimationRequest
 */
export interface DefaultApiMyPropertiesControllerGetSellingEstimationRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiMyPropertiesControllerGetSellingEstimation
     */
    readonly id: string
}

/**
 * Request parameters for myPropertiesControllerInquireSellingEstimation operation in DefaultApi.
 * @export
 * @interface DefaultApiMyPropertiesControllerInquireSellingEstimationRequest
 */
export interface DefaultApiMyPropertiesControllerInquireSellingEstimationRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiMyPropertiesControllerInquireSellingEstimation
     */
    readonly id: string
}

/**
 * Request parameters for myPropertiesControllerRemove operation in DefaultApi.
 * @export
 * @interface DefaultApiMyPropertiesControllerRemoveRequest
 */
export interface DefaultApiMyPropertiesControllerRemoveRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiMyPropertiesControllerRemove
     */
    readonly id: string
}

/**
 * Request parameters for myPropertiesControllerRequestEvaluationReport operation in DefaultApi.
 * @export
 * @interface DefaultApiMyPropertiesControllerRequestEvaluationReportRequest
 */
export interface DefaultApiMyPropertiesControllerRequestEvaluationReportRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiMyPropertiesControllerRequestEvaluationReport
     */
    readonly id: string
}

/**
 * Request parameters for myPropertiesControllerUpdate operation in DefaultApi.
 * @export
 * @interface DefaultApiMyPropertiesControllerUpdateRequest
 */
export interface DefaultApiMyPropertiesControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiMyPropertiesControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdateMyPropertyDto}
     * @memberof DefaultApiMyPropertiesControllerUpdate
     */
    readonly updateMyPropertyDto: UpdateMyPropertyDto
}

/**
 * Request parameters for propertiesControllerFindAll operation in DefaultApi.
 * @export
 * @interface DefaultApiPropertiesControllerFindAllRequest
 */
export interface DefaultApiPropertiesControllerFindAllRequest {
    /**
     * 
     * @type {'createdAt' | 'updatedAt' | 'address.principal' | 'address.city'}
     * @memberof DefaultApiPropertiesControllerFindAll
     */
    readonly orderBy?: 'createdAt' | 'updatedAt' | 'address.principal' | 'address.city'

    /**
     * 
     * @type {string}
     * @memberof DefaultApiPropertiesControllerFindAll
     */
    readonly filterBy?: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiPropertiesControllerFindAll
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultApiPropertiesControllerFindAll
     */
    readonly limit?: number

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof DefaultApiPropertiesControllerFindAll
     */
    readonly direction?: 'asc' | 'desc'
}

/**
 * Request parameters for propertiesControllerFindOne operation in DefaultApi.
 * @export
 * @interface DefaultApiPropertiesControllerFindOneRequest
 */
export interface DefaultApiPropertiesControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiPropertiesControllerFindOne
     */
    readonly id: string
}

/**
 * Request parameters for propertiesControllerGetLoanPayments operation in DefaultApi.
 * @export
 * @interface DefaultApiPropertiesControllerGetLoanPaymentsRequest
 */
export interface DefaultApiPropertiesControllerGetLoanPaymentsRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiPropertiesControllerGetLoanPayments
     */
    readonly id: string
}

/**
 * Request parameters for propertiesControllerSimulate operation in DefaultApi.
 * @export
 * @interface DefaultApiPropertiesControllerSimulateRequest
 */
export interface DefaultApiPropertiesControllerSimulateRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiPropertiesControllerSimulate
     */
    readonly id: string
}

/**
 * Request parameters for railwayCompaniesControllerFindOne operation in DefaultApi.
 * @export
 * @interface DefaultApiRailwayCompaniesControllerFindOneRequest
 */
export interface DefaultApiRailwayCompaniesControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiRailwayCompaniesControllerFindOne
     */
    readonly companyCode: string
}

/**
 * Request parameters for railwayLinesControllerFindOne operation in DefaultApi.
 * @export
 * @interface DefaultApiRailwayLinesControllerFindOneRequest
 */
export interface DefaultApiRailwayLinesControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiRailwayLinesControllerFindOne
     */
    readonly lineCode: string
}

/**
 * Request parameters for railwayStationsControllerFindAll operation in DefaultApi.
 * @export
 * @interface DefaultApiRailwayStationsControllerFindAllRequest
 */
export interface DefaultApiRailwayStationsControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiRailwayStationsControllerFindAll
     */
    readonly lineCode: string
}

/**
 * Request parameters for railwayStationsControllerFindOne operation in DefaultApi.
 * @export
 * @interface DefaultApiRailwayStationsControllerFindOneRequest
 */
export interface DefaultApiRailwayStationsControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiRailwayStationsControllerFindOne
     */
    readonly lineCode: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiRailwayStationsControllerFindOne
     */
    readonly stationCode: string
}

/**
 * Request parameters for usersControllerLineAccountLinkCallback operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersControllerLineAccountLinkCallbackRequest
 */
export interface DefaultApiUsersControllerLineAccountLinkCallbackRequest {
    /**
     * 
     * @type {LineCallbackBodyDto}
     * @memberof DefaultApiUsersControllerLineAccountLinkCallback
     */
    readonly lineCallbackBodyDto: LineCallbackBodyDto
}

/**
 * Request parameters for usersControllerLineCallback operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersControllerLineCallbackRequest
 */
export interface DefaultApiUsersControllerLineCallbackRequest {
    /**
     * 
     * @type {LineCallbackBodyDto}
     * @memberof DefaultApiUsersControllerLineCallback
     */
    readonly lineCallbackBodyDto: LineCallbackBodyDto
}

/**
 * Request parameters for usersControllerRegister operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersControllerRegisterRequest
 */
export interface DefaultApiUsersControllerRegisterRequest {
    /**
     * 
     * @type {RegisterUserDto}
     * @memberof DefaultApiUsersControllerRegister
     */
    readonly registerUserDto: RegisterUserDto
}

/**
 * Request parameters for usersControllerUpdateUser operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersControllerUpdateUserRequest
 */
export interface DefaultApiUsersControllerUpdateUserRequest {
    /**
     * 
     * @type {UpdateUserDto}
     * @memberof DefaultApiUsersControllerUpdateUser
     */
    readonly updateUserDto: UpdateUserDto
}

/**
 * Request parameters for usersControllerVerifyCode operation in DefaultApi.
 * @export
 * @interface DefaultApiUsersControllerVerifyCodeRequest
 */
export interface DefaultApiUsersControllerVerifyCodeRequest {
    /**
     * 
     * @type {VerifyCodeDto}
     * @memberof DefaultApiUsersControllerVerifyCode
     */
    readonly verifyCodeDto: VerifyCodeDto
}

/**
 * Request parameters for xaxisLabelsControllerGetXAxisLabels operation in DefaultApi.
 * @export
 * @interface DefaultApiXaxisLabelsControllerGetXAxisLabelsRequest
 */
export interface DefaultApiXaxisLabelsControllerGetXAxisLabelsRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiXaxisLabelsControllerGetXAxisLabels
     */
    readonly startDate: string

    /**
     * 
     * @type {number}
     * @memberof DefaultApiXaxisLabelsControllerGetXAxisLabels
     */
    readonly numberOfYears: number

    /**
     * 
     * @type {'by-month' | 'by-year'}
     * @memberof DefaultApiXaxisLabelsControllerGetXAxisLabels
     */
    readonly cycle: 'by-month' | 'by-year'
}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {DefaultApiAddressInfoControllerGetAddressInfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addressInfoControllerGetAddressInfo(requestParameters: DefaultApiAddressInfoControllerGetAddressInfoRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).addressInfoControllerGetAddressInfo(requestParameters.zipCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public appControllerGetHello(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).appControllerGetHello(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiMyPropertiesControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public myPropertiesControllerCreate(requestParameters: DefaultApiMyPropertiesControllerCreateRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).myPropertiesControllerCreate(requestParameters.createMyPropertyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiMyPropertiesControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public myPropertiesControllerFindAll(requestParameters: DefaultApiMyPropertiesControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).myPropertiesControllerFindAll(requestParameters.orderBy, requestParameters.filterBy, requestParameters.page, requestParameters.limit, requestParameters.direction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiMyPropertiesControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public myPropertiesControllerFindOne(requestParameters: DefaultApiMyPropertiesControllerFindOneRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).myPropertiesControllerFindOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiMyPropertiesControllerGetSellingEstimationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public myPropertiesControllerGetSellingEstimation(requestParameters: DefaultApiMyPropertiesControllerGetSellingEstimationRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).myPropertiesControllerGetSellingEstimation(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiMyPropertiesControllerInquireSellingEstimationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public myPropertiesControllerInquireSellingEstimation(requestParameters: DefaultApiMyPropertiesControllerInquireSellingEstimationRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).myPropertiesControllerInquireSellingEstimation(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiMyPropertiesControllerRemoveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public myPropertiesControllerRemove(requestParameters: DefaultApiMyPropertiesControllerRemoveRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).myPropertiesControllerRemove(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiMyPropertiesControllerRequestEvaluationReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public myPropertiesControllerRequestEvaluationReport(requestParameters: DefaultApiMyPropertiesControllerRequestEvaluationReportRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).myPropertiesControllerRequestEvaluationReport(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiMyPropertiesControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public myPropertiesControllerUpdate(requestParameters: DefaultApiMyPropertiesControllerUpdateRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).myPropertiesControllerUpdate(requestParameters.id, requestParameters.updateMyPropertyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiPropertiesControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public propertiesControllerFindAll(requestParameters: DefaultApiPropertiesControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).propertiesControllerFindAll(requestParameters.orderBy, requestParameters.filterBy, requestParameters.page, requestParameters.limit, requestParameters.direction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiPropertiesControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public propertiesControllerFindOne(requestParameters: DefaultApiPropertiesControllerFindOneRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).propertiesControllerFindOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiPropertiesControllerGetLoanPaymentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public propertiesControllerGetLoanPayments(requestParameters: DefaultApiPropertiesControllerGetLoanPaymentsRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).propertiesControllerGetLoanPayments(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiPropertiesControllerSimulateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public propertiesControllerSimulate(requestParameters: DefaultApiPropertiesControllerSimulateRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).propertiesControllerSimulate(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public railwayCompaniesControllerFindAll(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).railwayCompaniesControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiRailwayCompaniesControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public railwayCompaniesControllerFindOne(requestParameters: DefaultApiRailwayCompaniesControllerFindOneRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).railwayCompaniesControllerFindOne(requestParameters.companyCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public railwayLinesControllerFindAll(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).railwayLinesControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiRailwayLinesControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public railwayLinesControllerFindOne(requestParameters: DefaultApiRailwayLinesControllerFindOneRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).railwayLinesControllerFindOne(requestParameters.lineCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiRailwayStationsControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public railwayStationsControllerFindAll(requestParameters: DefaultApiRailwayStationsControllerFindAllRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).railwayStationsControllerFindAll(requestParameters.lineCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiRailwayStationsControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public railwayStationsControllerFindOne(requestParameters: DefaultApiRailwayStationsControllerFindOneRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).railwayStationsControllerFindOne(requestParameters.lineCode, requestParameters.stationCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersControllerGetLinkedAccounts(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersControllerGetLinkedAccounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersControllerLineAccountLink(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersControllerLineAccountLink(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiUsersControllerLineAccountLinkCallbackRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersControllerLineAccountLinkCallback(requestParameters: DefaultApiUsersControllerLineAccountLinkCallbackRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersControllerLineAccountLinkCallback(requestParameters.lineCallbackBodyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersControllerLineAccountUnlink(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersControllerLineAccountUnlink(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiUsersControllerLineCallbackRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersControllerLineCallback(requestParameters: DefaultApiUsersControllerLineCallbackRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersControllerLineCallback(requestParameters.lineCallbackBodyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersControllerLineLogin(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersControllerLineLogin(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiUsersControllerRegisterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersControllerRegister(requestParameters: DefaultApiUsersControllerRegisterRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersControllerRegister(requestParameters.registerUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersControllerResendVerificationCode(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersControllerResendVerificationCode(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiUsersControllerUpdateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersControllerUpdateUser(requestParameters: DefaultApiUsersControllerUpdateUserRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersControllerUpdateUser(requestParameters.updateUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiUsersControllerVerifyCodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersControllerVerifyCode(requestParameters: DefaultApiUsersControllerVerifyCodeRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersControllerVerifyCode(requestParameters.verifyCodeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiXaxisLabelsControllerGetXAxisLabelsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public xaxisLabelsControllerGetXAxisLabels(requestParameters: DefaultApiXaxisLabelsControllerGetXAxisLabelsRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).xaxisLabelsControllerGetXAxisLabels(requestParameters.startDate, requestParameters.numberOfYears, requestParameters.cycle, options).then((request) => request(this.axios, this.basePath));
    }
}


